import React from "react";
import "./../../Components/styles.css";
import pic1 from "./../../Assets/customLogo.png";
import pic2 from "./../../Assets/login.svg";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
import { base_url2 } from "./../../constants";
import Axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button, TextField, Typography, Divider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme } from "./../../Common/Theme";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  saveRegistrationDetails,
  resetPasswordChange,
  getBranches,
  UserQueryClearData,
  forgotPasswordOtpGenerate,
  forgotPasswordOtpValidate,
  verifyEmailandMobile,
  otpVerification,
  saveUserRegisteration,
  getChannelNames,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import MenuItem from "@mui/material/MenuItem";
import { OTPInputField } from "react-otp-input-type";
import CustomLoader from "./../../Common/corestratLoader";
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "./../../Common/Footer";
import Alert from "@mui/material/Alert";
import GenInsight from "../GenInsight/GenInsight";
import Header from "../../Common/Header/Header";
import { maxHeight, width } from "@mui/system";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function Login() {
  const [error, setError] = React.useState(false);
  const [islogin, setislogin] = React.useState(true);
  const [loginCompleted, setloginCompleted] = React.useState(true);
  const [seconds, setSeconds] = useState(120);
  const [emailError, setEmailError] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [isValidPass, setIsValidPass] = React.useState(false);
  const [duplicateRecord, setduplicateRecord] = React.useState(false);
  const [UsernameValid, setUsernameValid] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [isValidPassword, setisValidPassword] = React.useState(false);
  const [message, setmessage] = React.useState("");
  const [resetPasswordFlag, setresetPasswordFlag] = React.useState(false);
  const [forgotPasswordFlag, setForgotPasswordFlag] = React.useState(false);
  const [otpGenerated, setOtpGenerated] = React.useState(false);
  const [emailMobileError, setEmailMobileError] = React.useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNoError, setPhoneNoError] = useState(false);
  const GenericMobileNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10,13}$/;
  const IndianMobileNumberRegex = /^\d{10}$/;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // useEffect(() => {
  //   let interval = null;
  //   if (seconds > 0) {
  //     interval = setInterval(() => {
  //       setSeconds((seconds) => seconds - 1);
  //     }, 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [seconds]);
  const OtpTimer = () => {
    let interval = null;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  };
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [resetPasswordFields, setresetPasswordFields] = useState({
    username: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordChange, setPasswordChange] = useState({
    username: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  })

  const [registration, setregistration] = useState({
    username: "",
    email: "",
    mobileNo: "",
    password: "",
    repassword: "",
    channel:"",
  });

  const [selectedBranchName, setselectedBranchName] = React.useState("");
  const branchName = useSelector(
    (state) => state.BasicDetailsReducers.branchname
  );
  const channelName = useSelector(
    (state) => state.BasicDetailsReducers.channelname
  );
  useEffect(() => {
    dispatch(getBranches());
    dispatch(getChannelNames());
  }, []);
  useEffect(() => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
  }, []);

  const setLogin = (login, e) => {
    setPasswordChange({ ...passwordChange, username:""})
    setCredentials({ ...credentials, username: "", password: "" });
    setregistration({
      ...registration,
      username: "",
      email: "",
      mobileNo: "",
      password: "",
      repassword: "",
      channel:"",
    });
    setislogin(login);
    setduplicateRecord(false);
    setError(false);
    setForgotPasswordFlag(false);
    setOtpGenerated(false);
    setEmailError(false);
    setIsValid(false);
    setIsValidPass(false);
    setPhoneNoError(false);
  };

  const handleInputChangePassword = (e) => {
    if (e.target.name == "username") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setresetPasswordFields({ ...resetPasswordFields, [e.target.name]: valueWithoutSpaces });
    } else {
      setresetPasswordFields({ ...resetPasswordFields, [e.target.name]: e.target.value });
    }
    if (e.target.name == "username") {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(e.target.value.trim()) || e.target.value == "") {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (e.target.name == "password") {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
      if (passwordRegex.test(e.target.value)) {
        setIsValidPass(false);
      } else {
        setIsValidPass(true);
      }
      if(e.target.value !==  resetPasswordFields.confirmPassword){
        setIsValid(true);
      }
      // const isPasswordValid = passwordRegex.test(e.target.value);
      // const passwordContainsUsername = resetPasswordFields.password.includes(
      //   resetPasswordFields.username
      // );
      // setIsValidPass(!isPasswordValid || passwordContainsUsername);
      // if(e.target.value!==resetPasswordFields.confirmPassword){
      //   setIsValid(true);
      // }
      // setIsValidPass(!isPasswordValid);
    }
    if (e.target.name == "confirmPassword") {
      if (e.target.value == resetPasswordFields.password) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };
  //----------------------Check if email or mobile number is right--------------------

  const handleInputForgotPasswordGenerateOtp = (e) => {
    setOtpGenerated(false);
    setEmailMobileError(false);
    setPasswordChange({ ...passwordChange, [e.target.name]: e.target.value });
    if (e.target.value) {
      // const regex = /^((\+91\d{10})|(\w+@\w+\.\w{2,3}))$/;
      const regex = /^(?:\+91\d{10}|[\w.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,})$/;
      if (regex.test(e.target.value)) {
        setEmailMobileError(false);
      } else {
        setEmailMobileError(true);
      }
    }
  }

  //----------------------------Handle forgot password feilds---------------------------
  const handleInputForgotPassword = (e) => {
    setPasswordChange({
      ...passwordChange,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "newPassword") {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
      const isPasswordValid = passwordRegex.test(e.target.value);
      // const passwordContainsUsername = passwordChange.newPassword.includes(
      //   passwordChange.username
      // );

      if (passwordChange.confirmPassword != e.target.value && passwordChange.confirmPassword != undefined) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
      setIsValidPass(!isPasswordValid);   //passwordContainsUsername
    }
    if (e.target.name == "confirmPassword") {
      if (e.target.value == passwordChange.newPassword) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };

  const changePassword = async () => {
    if (emailError || isValid || isValidPass) {
    } else {
      const results = await resetPasswordChange(resetPasswordFields);
      setError(false);
      if (results.data.msg != "Password Updated") {
        setmessage(results.data.msg);
      } else {
        resetPassword();
      }
    }
  };

  const resetPassword = () => {
    setresetPasswordFlag(!resetPasswordFlag);
    setCredentials({ ...credentials, username: "", password: "" });
    setresetPasswordFields({
      ...resetPasswordFields,
      username: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
    setmessage("");
    setError(false);
    setisValidPassword(false);
    setForgotPasswordFlag(false);
    setOtpGenerated(false);
    setEmailError(false);
    setIsValid(false);
    setIsValidPass(false);
    setEmailMobileError(false);
  };

  const forgotPassword = () => {
    setPasswordChange({ ...passwordChange, username:""});
    setForgotPasswordFlag(!forgotPasswordFlag);
    // setPasswordChange({})
    setOtpGenerated(false);
    setError(false);
    setEmailMobileError(false);
    setIsValidPass(false);
    setIsValid(false);
  }

  const handleClose = () => {
    setOpen1(false);
    setduplicateRecord(false);
    setloginCompleted(true);
    setCredentials({ ...credentials, username: "", password: "" });
    setOTPerror(false);
    // setregistration({
    //   ...registration,
    //   username: "",
    //   email: "",
    //   mobileNo: "",
    //   password: "",
    //   repassword: "",
    //   // selectedChannelName:"",
    // });
  };
  const handleInputChange = (e) => {
    if (e.target.name == "username") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setCredentials({ ...credentials, [e.target.name]: valueWithoutSpaces });
    } else {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };
  const [selectedChannelName, setselectedChannelName] = React.useState("");
  const handleInputChangeRegistration = (e) => {
    if (e.target.name == "email") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setregistration({ ...registration, [e.target.name]: valueWithoutSpaces });
    } else {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
    if (e.target.name == "email") {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(e.target.value.trim()) || e.target.value == "") {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (e.target.name == "channel") {
      setselectedChannelName(e.target.value);
      console.log(e.target.value);
    }
    if (e.target.name == "mobileNo") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
      // Check if the input matches the generic regex pattern
      const isValid =
        IndianMobileNumberRegex.test(e.target.value) || e.target.value == "";

      // Update the error state based on the validation result
      setPhoneNoError(!isValid);
    }
    if (e.target.name == "password") {
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
        if (passwordRegex.test(e.target.value)) {
          setIsValidPass(false);
        } else {
          setIsValidPass(true);
        }
        if(e.target.value !==  registration.repassword){
          setIsValid(true);
        }
      // const isPasswordValid = passwordRegex.test(e.target.value);
      // const passwordContainsUsername = registration.password.includes(
      //   registration.username
      // );
      // setIsValidPass(!isPasswordValid || passwordContainsUsername);
      // if(e.target.value !== registration.repassword){
      //   setIsValid(true);
      // }
    }
    if (e.target.name == "repassword") {
      if (e.target.value == registration.password) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  };
  const [open1, setOpen1] = React.useState(false);

  const handleLoginSignup = async (e) => {
    setCredentials({ ...credentials, username: "", password: "" });
    setduplicateRecord(false);
    setloginCompleted(false);
    // if (registration.email == "" && registration.mobileNo == "") {
    //   setEmailError(true);
    // }
    if (
      (registration.email == "" && registration.mobileNo == "") ||
      registration.username == "" ||
      registration.password == "" ||
      registration.repassword == "" ||
      selectedBranchName == "" || selectedChannelName ==""
    ) {
      setloginCompleted(true);
      return;
    }
    const matchingBranches = branchName.response_data.filter((branch) =>
      branch.Branch_Name.includes(selectedBranchName)
    );

    const matchingChannels = channelName.data.filter((channel) =>
      channel.channel_display_name.includes(selectedChannelName)
    );


    // Now matchingBranches contains objects where the bank name is found in branchName
    if (emailError || isValid || phoneNoError || isValidPass) {
    } else {
      setOTPerror(false);
      // const response = await saveRegistrationDetails(
      //   registration,
      //   matchingChannels,
      //   matchingBranches
      // );
      // if (
      //   response.data.message &&
      //   response.data.message?.trim() === "User Exists Already!"
      // ) {
      //   setduplicateRecord(true);
      //   setloginCompleted(true);
      // } else {
      //   OtpTimer();
      //   setOpen1(true);
      //   setloginCompleted(true);
      //   localStorage.setItem("EmailId", registration.email);
      //   localStorage.setItem("channelName", matchingChannels);
      // }

      //-----------------------
      const response = await saveUserRegisteration(registration, matchingChannels, matchingBranches);
      if (
        response.data.message &&
        response.data.message?.trim() === "User already registered."
      ) {
        setduplicateRecord(true);
        setloginCompleted(false);
      } else {
        setislogin(true);
        setloginCompleted(true);
        setEmailVerifyButton(false);
        setMobileVerifyButton(false);
        localStorage.setItem("EmailId", registration.email);
        localStorage.setItem("channelName", e.target.value);
      }
      //-----------------------

      //navigate("/BasicDetails");
    }
    setEmailError(false);
    setIsValid(false);
    setPhoneNoError(false);
    setIsValidPass(false);

  };
  const resendOTP = async (e) => {
    if(verifyEmail == true){
    const response = await dispatch(verifyEmailandMobile(registration.email,true));
    }
    else{
      const response = await dispatch(verifyEmailandMobile(registration.mobileNo,false));
    }
    // const matchingBranches = branchName.response_data.filter((branch) =>
    //   branch.Branch_Name.includes(selectedBranchName)
    // );
    // const matchingChannels = channelName.data.filter((channel) =>
    //   channel.channel_display_name.includes(selectedChannelName)
    // );
    // const response = await saveRegistrationDetails(
    //   registration,
    //   matchingChannels,
    //   matchingBranches
    // );
  };
  const handleOTPChange = (val) => {
    setOtp(val);
  };

  const [emailVerifyButton, setEmailVerifyButton] = useState(false);
  const [mobileVerifyButton, setMobileVerifyButton] = useState(false);

  const verifyOTP = async () => {
    setOTPerror(false);
    setloginCompleted(false);
    // Axios.post(`${base_url2}/dla_user_otp_verify`, {
    //   email_id: registration.email,
    //   mobile_no: registration.mobileNo,
    //   email_verification_code: otp,
    // }).then((result) => {
    //   if (result.data.data[0].Result != "success") {
    //     setOTPerror(true);
    //     setloginCompleted(true);
    //   } else {
    //     setOTPerror(false);
    //     setislogin(true);
    //     setloginCompleted(true);
    //     //navigate("/BasicDetails");
    //   }
    // });
    if(verifyEmail){
      const response = await dispatch(otpVerification(registration.email, true, otp));
      if(response?.status == 200){
        setEmailVerifyButton(true);
        setOpen1(false);
        setloginCompleted(true);
      }else{
        setOTPerror(false);
      }
    }
    else{
      const response = await dispatch(otpVerification(registration.mobileNo, false, otp));
      if(response?.status == 200){
        setMobileVerifyButton(true);
        setOpen1(false);
        setloginCompleted(true);
      }else{
        setOTPerror(true);
      }
    }
  };
  //----------------------------Send otp to phone no or email address-----------------
  const forgotPasswordOtp = () => {
    setOtpGenerated(true);
    setOTPerror(false);
    setloginCompleted(true);
    console.log(passwordChange.username);
    dispatch(forgotPasswordOtpGenerate(passwordChange.username));
    // if(response){
    //   setloginCompleted(false);
    // }
    // if(response.error){
    //   console.log("hi");
    // }
  }

  //--------------------------Validate the otp and enter and save new password-----------------
  const changePasswordButton = () => {
    setloginCompleted(false);
    setError(false);
    setCredentials({ ...credentials, username: "", password: "" });
    console.log(passwordChange.otp);
    dispatch(forgotPasswordOtpValidate(passwordChange.otp, passwordChange.newPassword));
    //navigation to login: (make it if api response is successful):
    setislogin(true);
    setresetPasswordFlag(false);
    setForgotPasswordFlag(false);
    //show loader if validated the otp
    //if password changed show the message in alert
    setEmailError(false);
    setIsValid(false);
    setIsValidPass(false);
    setloginCompleted(false);
  }

  const [verifyEmail, setVerifyEmail] = useState(false);

  const handleVerify = async (e, type)=>{
    setOpen1(true);
    if(type == "email"){
      setVerifyEmail(true);
      const response = await dispatch(verifyEmailandMobile(registration.email,true));
    }
    if(type == "mobile"){
      setVerifyEmail(false);
      const response = await dispatch(verifyEmailandMobile(registration.mobileNo,false));
    }
  }

  const validateUsers = () => {
    setloginCompleted(false);
    setError(false);

    Axios.post(`${base_url2}/user_login`, {
      email_id: credentials.username,
      login_password: credentials.password,
    }).then((result) => {
      if (!result.data.response_message?.startsWith("SUCCESS")) {
        setError(true);
        setloginCompleted(true);
      } else {
        setError(false);
        localStorage.setItem("EmailId", result.data.response_data.email_id);
        localStorage.setItem("branch", result.data.response_data.branch);
        localStorage.setItem("jwt_token", result.data.response_data.jwt_token);
        const token_decode = jwtDecode(result.data.response_data.jwt_token);  
        localStorage.setItem("channelName", token_decode?.channel_name);
        // setloginCompleted(true);
        if (credentials.username == "agrim@corestrat.ai") {
          navigate("/BulkUpload");
        } else {
          navigate("/BasicDetails");
        }
        dispatch(UserQueryClearData());
        localStorage.removeItem("LoanApplicationId");
      }
    });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      {!loginCompleted ? (
        <Grid item xs={12}
        //sx={{ m: 1 }}
        >
          <LinearProgress
            sx={{
              color: "#148F5B",
            }}
            size={40}
            thickness={4}
          />
        </Grid>
      ) : null}
      <div
        style={{
          background: "#F0EDE4",
        }}
      >
        <Header />
      </div>

      {/* <GenInsight /> */}
      <Box
        maxWidth
        align="center"
        style={{
          background: "#F0EDE4",
          height: "95vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          textAlign="center"
          style={{
            width: "60rem",
            // height: "78vh",
            boxShadow: "0 4px 12px rgb(0 0 0 / 15%)",
            background: "#ffffff",
            borderRadius: "20px",
            // paddingTop: "12%",
            marginTop:"5%"
          }}
        >
          <Grid item xs={12}>
            {!islogin ? (
              <Grid container spacing={4}
                // style={{
                //   height: "90%",
                // }}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    style={{
                      width: "100%",
                      height: "78vh",
                      backgroundColor: "#890044",
                      textAlign: "center",
                      color: "#ffffff",
                      padding: "20px",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: "20px 0px 0px 20px",
                      // marginTop:"20px"
                    }}
                  >
                    <div
                      style={{
                        fontSize: "35px",
                        marginBottom: "15%",
                        textAlign: "left",
                        fontWeight: "600",
                      }}
                    >
                      Humanizing the <br />
                      Experience of Finance
                    </div>
                    <div style={{ fontSize: "20px", textAlign: "left" }}>
                      Welcome to Gosree Finance - your hassle-free banking
                      partner!
                    </div>
                    <br />
                    <br />
                    <div style={{ fontSize: "20px", textAlign: "left" }}>
                      Paperless loans at your fingertips!
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{ marginTop: "3%" }}>
                  <Box>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "800",
                        // marginTop:"4%"
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "#000000",
                        }}
                      >
                        Gosree Instant Loans
                      </span>{" "}
                    </Typography>
                  </Box>
                  <Box style={{ margin: "3%" }}>
                    <Typography variant="h6"> Sign Up </Typography>
                  </Box>
                  <Box style={{ margin: "5%" }}>
                    <Divider />
                  </Box>
                  {duplicateRecord ? (
                    <Alert severity="error">This user already exists!</Alert>
                  ) : (
                    ""
                  )}
                  <div style={{ height: "33vh", overflow: "auto" }}>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        error={UsernameValid}
                        label="Name (as per PAN) *"
                        variant="standard"
                        name="username"
                        value={registration.username}
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={
                          UsernameValid ? "Username cannot be Empty" : null
                        }
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                    <Grid container>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        label="Email "
                        error={emailError}
                        variant="standard"
                        value={registration.email}
                        name="email"
                        disabled={emailVerifyButton}
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={emailError ? "Email is Invalid." : null}
                      />
                      </Grid>
                      <Grid item xs={3}>
                          <Button
                          size="small"
                          variant="contained"
                          sx={{
                            mt:2,
                            background: "#148F5B",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          onClick={(e) => handleVerify(e, "email")}
                          disabled={registration.email == "" || emailVerifyButton || emailError}
                          >
                           Verify
                          </Button>
                        </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <Grid container>
                        <Grid item xs={9}>
                         <TextField
                           fullWidth
                           type="text"
                           label="Mobile No. "
                           variant="standard"
                           name="mobileNo"
                           disabled={mobileVerifyButton}
                           value={registration.mobileNo}
                           onChange={(e) => handleInputChangeRegistration(e)}
                           error={phoneNoError}
                           helperText={phoneNoError ? "Invalid mobile number" : ""}
                         />
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                          size="small"
                          variant="contained"
                          sx={{
                            mt:2,
                            background: "#148F5B",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          onClick={(e) => handleVerify(e, "mobile")}
                          disabled={registration.mobileNo == "" || mobileVerifyButton || phoneNoError}
                          >
                           Verify
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        label="Password *"
                        type="password"
                        variant="standard"
                        value={registration.password}
                        error={isValidPass}
                        name="password"
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={
                          isValidPass
                            ? "Password must be at least 8 characters long and contain at least one letter and one number."
                            : null
                        }
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        label="Repeat Password *"
                        type="password"
                        error={isValid}
                        variant="standard"
                        value={registration.repassword}
                        name="repassword"
                        onChange={(e) => handleInputChangeRegistration(e)}
                        helperText={isValid ? "Password Doesnt Match" : null}
                      />
                    </Box>
                    <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        select
                        label="Branch Name"
                        id="Branch Name"
                        value={selectedBranchName}
                        onChange={(event) =>
                          setselectedBranchName(event.target.value)
                        }
                        sx={{
                          textAlign: "left", // Align text to the left
                          // Add other styling properties if needed
                        }}
                        variant="standard"
                      >
                        {/* Static options */}
                        {branchName?.response_data?.map((option) => (
                          <MenuItem
                            key={option.Branch_Name}
                            value={option.Branch_Name}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #148F5B",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.Branch_Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                      <Box style={{ margin: "3%" }}>
                      <TextField
                        fullWidth
                        select
                        label="Channel Name"
                        id="Channel Name"
                        name="channel"
                        value={selectedChannelName}
                        onChange={(e)=>handleInputChangeRegistration(e)}
                        // onChange={(event) =>setselectedChannelName(event.target.value)}
                        sx={{
                          textAlign: "left", // Align text to the left
                          // Add other styling properties if needed
                        }}
                        variant="standard"
                      >
                        {/* {menuItems.map((item) => (
                          <MenuItem key={item} value={item} sx={menuItemStyles}>
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </MenuItem>
                        ))} */}
                        {channelName?.data?.map((option) => (
                          <MenuItem
                            key={option.channel_display_name}
                            value={option.channel_display_name}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #148F5B",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.channel_display_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                  <Box textAlign="center" style={{ margin: "5%" }}>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        background: "#148F5B",
                        color: "#ffffff",
                        borderRadius: "20px",
                      }}
                      onClick={(e) => handleLoginSignup(e)}
                      disabled={registration.username=="" || registration.password=="" || registration.repassword =="" || isValid || isValidPass || !emailVerifyButton || !mobileVerifyButton || selectedChannelName==""}
                      // disabled={registration.username=="" || registration.password=="" || registration.repassword =="" || isValid || isValidPass || selectedChannelName==""}
                    >
                      Sign Up
                    </Button>
                    <BootstrapDialog
                      className="optCSS"
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open1}
                    >
                      <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        style={{ fontWeight: "800", color: "#148F5B" }}
                      >
                        Verify
                      </BootstrapDialogTitle>

                      <DialogContent>
                        {OTPerror ? (
                          <Box
                            className="errorMessage"
                            style={{ margin: "5%" }}
                          >
                            Invalid OTP
                          </Box>
                        ) : (
                          ""
                        )}
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                            }}
                          >
                            We have sent you <b>One Time Password</b> to your
                            {verifyEmail ? " email" : " mobile"}.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                              color: "#148F5B",
                            }}
                          >
                            Please Enter OTP
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={6} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                              fontWeight: "800",
                            }}
                          >
                            {`${minutes}:${
                              remainingSeconds < 10 ? "0" : ""
                            }${remainingSeconds}`}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <OTPInputField
                            className="otp"
                            numOfInputs={6}
                            handleChange={handleOTPChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="buttonText"
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#148F5B",
                                fontWeight: "800",
                                m: 2,
                              }}
                              onClick={(e) => resendOTP(e)}
                              className="buttonText"
                            >
                              Resend OTP
                            </Button>

                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "800",
                                m: 2,
                              }}
                              className="buttonText1"
                              onClick={verifyOTP}
                            >
                              Verify OTP
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#148F5B",
                                fontWeight: "800",
                              }}
                              onClick={handleClose}
                            >
                              Login with another account
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </BootstrapDialog>
                  </Box>
                  <Box textAlign="center" style={{ margin: "5%" }}>
                    <Typography variant="body2">
                      {" "}
                      Already have an account ?{" "}
                      <Link onClick={(e) => setLogin(true, e)}>
                        Login here
                      </Link>{" "}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <>
                {!resetPasswordFlag ?
                  (<>
                    {forgotPasswordFlag ? (<>
                      <Grid
                        container
                        spacing={4}
                      // style={{
                      //   height: "85vh",
                      // }}
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <div
                            style={{
                              width: "100%",
                              height: "78vh",
                              backgroundColor: "#890044",
                              textAlign: "center",
                              color: "#ffffff",
                              padding: "20px",
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              borderRadius: "20px 0px 0px 20px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "35px",
                                marginBottom: "15%",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              Humanizing the <br />
                              Experience of Finance
                            </div>
                            <div style={{ fontSize: "20px", textAlign: "left" }}>
                              Welcome to Gosree Finance - your hassle-free banking
                              partner!
                            </div>
                            <br />
                            <br />
                            <div style={{ fontSize: "20px", textAlign: "left" }}>
                              Paperless loans at your fingertips!
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          style={{ marginTop: "5%" }}
                        >
                          <Box style={{ margin: "3%" }}>
                            <Typography
                              variant="h5"
                              style={{
                                fontWeight: "800",
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  color: "#000000",
                                }}
                              >
                                Gosree Instant Loans
                              </span>{" "}
                            </Typography>
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <Typography variant="h6"> Forgot Password </Typography>
                          </Box>
                          <Box style={{ margin: "5%" }}>
                            <Divider />
                          </Box>
                          {/* {error ? (
                        <Alert severity="error">
                          Incorrect Email or Password
                        </Alert>
                      ) : (
                        ""
                      )} */}
                          <div style={{ height: "33vh", overflow: "auto" }}>
                            <Box style={{ margin: "3%" }}>
                              <TextField
                                fullWidth
                                label="Email/Mobile No.(with country code)"
                                variant="standard"
                                name="username"
                                value={passwordChange.username}
                                onChange={(e) => handleInputForgotPasswordGenerateOtp(e)}
                                error={emailMobileError}
                                helperText={emailMobileError && "Enter a valid email or mobile number"}
                              // disabled={otpGenerated}
                              />
                            </Box>
                            {!otpGenerated ? (<>
                              <Box textAlign="center" style={{ margin: "5%" }}>
                                <Button
                                  size="large"
                                  variant="contained"
                                  // style={{
                                  //   background: "#148F5B",
                                  //   color: "#ffffff",
                                  //   borderRadius: "20px",
                                  // }}
                                  sx={{
                                    background: "#148F5B",
                                    color: "#ffffff",
                                    borderRadius: "20px",
                                    textTransform: "none",
                                  }}
                                  onClick={forgotPasswordOtp}
                                  disabled={emailMobileError || passwordChange.username == "" || passwordChange.username == undefined}
                                >
                                  Generate OTP
                                </Button>
                              </Box>
                            </>) : (<>
                              {/*Alert message with the api response*/}
                              <Box style={{ margin: "3%" }}>
                                <TextField
                                  fullWidth
                                  label="OTP"
                                  type="otp"
                                  variant="standard"
                                  name="otp"
                                  value={passwordChange.otp}
                                  onChange={(e) => handleInputForgotPassword(e)}
                                />
                              </Box>
                              <Box style={{ margin: "3%" }}>
                                <TextField
                                  fullWidth
                                  label="New Password"
                                  type="password"
                                  variant="standard"
                                  name="newPassword"
                                  error={isValidPass}
                                  value={passwordChange.newPassword}
                                  onChange={(e) => handleInputForgotPassword(e)}
                                  helperText={
                                    isValidPass
                                      ? "Password must be at least 8 characters long and contain at least one letter and one number."
                                      : null
                                  }
                                />
                              </Box>
                              <Box style={{ margin: "3%" }}>
                                <TextField
                                  fullWidth
                                  label="Confirm Password"
                                  type="password"
                                  variant="standard"
                                  name="confirmPassword"
                                  value={passwordChange.confirmPassword}
                                  onChange={(e) => handleInputForgotPassword(e)}
                                  error={isValid}
                                  helperText={
                                    isValid ? "Password Doesnt Match" : null
                                  }
                                />
                              </Box>
                              <Box
                                style={{ margin: "5%", display: "flex", justifyContent: "space-between" }}
                                sx={{
                                  color: "#148F5B !important",
                                  fontWeight: "800 !important",
                                }}
                              >
                              </Box>
                            </>)}
                          </div>
                          {otpGenerated &&
                            <Box textAlign="center" style={{ margin: "5%" }}>
                              <Button
                                size="large"
                                variant="contained"
                                // style={{
                                //   background: "#148F5B",
                                //   color: "#ffffff",
                                //   borderRadius: "20px",
                                // }}
                                sx={{
                                  background: "#148F5B",
                                  color: "#ffffff",
                                  borderRadius: "20px",
                                }}
                                onClick={changePasswordButton}
                                disabled={isValid || isValidPass || passwordChange.otp == undefined || passwordChange.newPassword == undefined || passwordChange.confirmPassword == undefined}
                              >
                                Submit
                              </Button>
                            </Box>
                          }
                          <Box textAlign="center" style={{ margin: "5%" }}>
                            <Typography variant="body2">
                              {" "}
                              Don't have an account ?{" "}
                              <Link onClick={(e) => setLogin(false, e)}>
                                Register here
                              </Link>{" "}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </>) : (<>
                      <Grid
                        container
                        spacing={4}
                      // style={{
                      //   height: "85vh",
                      //   // height: "50vh",
                      // }}
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <div
                            style={{
                              width: "100%",
                              height: "78vh",
                              backgroundColor: "#890044",
                              textAlign: "center",
                              color: "#ffffff",
                              padding: "20px",
                              boxSizing: "border-box",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              borderRadius: "20px 0px 0px 20px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "35px",
                                marginBottom: "15%",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              Humanizing the <br />
                              Experience of Finance
                            </div>
                            <div style={{ fontSize: "20px", textAlign: "left" }}>
                              Welcome to Gosree Finance - your hassle-free banking
                              partner!
                            </div>
                            <br />
                            <br />
                            <div style={{ fontSize: "20px", textAlign: "left" }}>
                              Paperless loans at your fingertips!
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          style={{ marginTop: "5%" }}
                        >
                          <Box style={{ margin: "3%" }}>
                            <Typography
                              variant="h5"
                              style={{
                                fontWeight: "800",
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  color: "#000000",
                                }}
                              >
                                Gosree Instant Loans
                              </span>{" "}
                            </Typography>
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <Typography variant="h6"> Login </Typography>
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <Divider />
                          </Box>
                          {error ? (
                            <Alert severity="error">
                              Incorrect Email or Password
                            </Alert>
                          ) : (
                            ""
                          )}
                          <Box style={{ margin: "5%" }}>
                            <TextField
                              fullWidth
                              label="Email Id"
                              // autoComplete="off"
                              variant="standard"
                              value={credentials.username}
                              name="username"
                              onChange={(e) => handleInputChange(e)}
                            />
                          </Box>
                          <Box style={{ margin: "5%" }}>
                            <TextField
                              fullWidth
                              label="Password"
                              type="password"
                              variant="standard"
                              name="password"
                              value={credentials.password}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </Box>
                          <Box
                            style={{ margin: "5%", display: "flex", justifyContent: "space-between" }}
                            sx={{
                              color: "#148F5B !important",
                              fontWeight: "800 !important",
                            }}
                          >
                            <Typography variant="body2" sx={{ mt: 2 }} style={{ textAlign: "left" }}>
                              {" "}
                              <Link onClick={(e) => forgotPassword()}>
                                Forgot Password
                              </Link>{" "}
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 2 }} style={{ textAlign: "right" }}>
                              {" "}
                              <Link onClick={(e) => resetPassword()}>
                                Reset Password
                              </Link>{" "}
                            </Typography>
                          </Box>
                          <Box textAlign="center" style={{ margin: "5%" }}>
                            <Button
                              size="large"
                              variant="contained"
                              sx={{
                                background: "#148F5B",
                                color: "#ffffff",
                                borderRadius: "20px",
                              }}
                              disabled={credentials.username==""||credentials.password==""}
                              onClick={validateUsers}
                            >
                              Login
                            </Button>
                          </Box>
                          <Box textAlign="center" style={{ margin: "5%" }}>
                            <Typography variant="body2">
                              {" "}
                              Don't have an account ?{" "}
                              <Link onClick={(e) => setLogin(false, e)}>
                                Register here
                              </Link>{" "}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </>)}</>
                  ) : (
                    <Grid container spacing={4}
                    // style={{
                    //   height: "85vh",
                    // }}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <div
                          style={{
                            width: "100%",
                            height: "78vh",
                            backgroundColor: "#890044",
                            textAlign: "center",
                            color: "#ffffff",
                            padding: "20px",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRadius: "20px 0px 0px 20px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "35px",
                              marginBottom: "15%",
                              textAlign: "left",
                              fontWeight: "600",
                            }}
                          >
                            Humanizing the <br />
                            Experience of Finance
                          </div>
                          <div style={{ fontSize: "20px", textAlign: "left" }}>
                            Welcome to Gosree Finance - your hassle-free banking
                            partner!
                          </div>
                          <br />
                          <br />
                          <div style={{ fontSize: "20px", textAlign: "left" }}>
                            Paperless loans at your fingertips!
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ marginTop: "5%" }}
                      >
                        <Box style={{ margin: "3%" }}>
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: "800",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                color: "#000000",
                              }}
                            >
                              Gosree Instant Loans
                            </span>{" "}
                          </Typography>
                        </Box>
                        <Box style={{ margin: "3%" }}>
                          <Typography variant="h6"> Login </Typography>
                        </Box>
                        <Box style={{ margin: "3%" }}>
                          <Divider />
                        </Box>
                        <div style={{ height: "38vh", overflow: "auto" }}>
                          {message != "" ? (
                            <Box
                              style={{ marginLeft: "5%", textAlign: "center" }}
                            >
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  ml: 5,
                                  ml: 0,
                                  justifyContent: "center",
                                }}
                              >
                                <Alert severity="error">{message}</Alert>
                              </Grid>
                            </Box>
                          ) : null}
                          <Box style={{ margin: "3%" }}>
                            <TextField
                              fullWidth
                              label="Email/Mobile No.(with country code)"
                              variant="standard"
                              name="username"
                              value={resetPasswordFields.username}
                              onChange={(e) => handleInputChangePassword(e)}
                              error={!!emailError}
                              helperText={emailError}
                            />
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <TextField
                              fullWidth
                              label="Old Password"
                              type="password"
                              variant="standard"
                              name="oldPassword"
                              value={resetPasswordFields.oldPassword}
                              onChange={(e) => handleInputChangePassword(e)}
                            />
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <TextField
                              fullWidth
                              label="New Password"
                              type="password"
                              variant="standard"
                              name="password"
                              error={isValidPass}
                              value={resetPasswordFields.password}
                              onChange={(e) => handleInputChangePassword(e)}
                              helperText={
                                isValidPass
                                  ? "Password must be at least 8 characters long and contain at least one letter and one number."
                                  : null
                              }
                            />
                          </Box>
                          <Box style={{ margin: "3%" }}>
                            <TextField
                              fullWidth
                              label="Confirm Password"
                              type="password"
                              variant="standard"
                              name="confirmPassword"
                              value={resetPasswordFields.confirmPassword}
                              onChange={(e) => handleInputChangePassword(e)}
                              error={isValid}
                              helperText={
                                isValid ? "Password Doesnt Match" : null
                              }
                            />
                          </Box>
                        </div>
                        {/* <Box style={{ cursor: "pointer", margin: "5%" }}>
                  <Typography align="left" variant="body2" color="textPrimary">
                    Forgot password
                  </Typography>
                </Box> */}
                        <Box
                          textAlign="right"
                          style={{ marginRight: "5%" }}
                          sx={{
                            color: "#148F5B !important",
                            fontWeight: "800 !important",
                          }}
                        >
                          <Typography variant="body2">
                            {" "}
                            <Link onClick={(e) => resetPassword()}>
                              Login Here
                            </Link>{" "}
                          </Typography>
                        </Box>
                        <Box textAlign="center" style={{ margin: "5%" }}>
                          <Button
                            size="large"
                            variant="contained"
                            sx={{
                              background: "#148F5B",
                              color: "#ffffff",
                              borderRadius: "20px",
                            }}
                            onClick={changePassword}
                            // disabled={isValid}
                          >
                            Reset Password
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
