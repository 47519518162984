import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import {
  gender,
  initialData,
  initialPANData,
} from "../Components/BasicDetails/BasicDetailsPages/constant";
import { OTPInputField } from "react-otp-input-type";
import Alert from "@mui/material/Alert";
import "./../Components/styles.css";
import adharVerification from "../Assets/adharVerification.svg";
import {
  InitiateSession,
  SavePANInformation,
  PANDrawer,
} from "../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateField } from "@mui/x-date-pickers/DateField";
import dayjs from "dayjs";
// import {
//   VerifyOTPMobile,
//   sendverificationCode,
// } from "./../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const PanVerification = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [jsonData, setJsonData] = useState(initialPANData);
  const [errorInPANDetails, setErrorInPANDetails] = useState(false);
  const panDrawer = useSelector(
    (state) => state.BasicDetailsReducers.panDrawer
  );
  const handleClose1 = () => {
    handleClose();
    setErrorInPANDetails(false);
    dispatch(PANDrawer(!panDrawer));
  };

  const handleChange = (event, type) => {
    setErrorInPANDetails(false);
    if (type == "first_name") {
      const newFirstName = event;
      const updatedData = { ...jsonData };
      updatedData.data.first_name = newFirstName;
      setJsonData(updatedData);
    }
    if (type == "middle_name") {
      const newMiddleName = event;
      const updatedData = { ...jsonData };
      updatedData.data.middle_name = newMiddleName;
      setJsonData(updatedData);
    }
    if (type == "last_name") {
      const newLastName = event;
      const updatedData = { ...jsonData };
      updatedData.data.last_name = newLastName;
      setJsonData(updatedData);
    }
    if (type == "gender") {
      const newGender = event;
      const updatedData = { ...jsonData };
      updatedData.data.gender = newGender;
      setJsonData(updatedData);
    }
    if (type == "dob") {
      const date = formatDate(event);
      const updatedData = { ...jsonData };
      updatedData.data.dob = date;
      const newFullName =
        `${updatedData.data.first_name} ${updatedData.data.middle_name} ${updatedData.data.last_name}`.trim();
      updatedData.data.full_name = newFullName;
      const newPan = data;
      updatedData.data.pan_number = newPan;
      setJsonData(updatedData);
    }
  };
  const savePANDetails = () => {
    console.log(jsonData);
    if (
      data == "" ||
      jsonData.data.first_name == "" ||
      jsonData.data.gender == "" ||
      jsonData.data.dob == ""
    ) {
      setErrorInPANDetails(true);
      return;
    } else {
      dispatch(SavePANInformation(jsonData));
      handleClose();
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const dob = new Date(date);
    const day = dob.getDate();
    const month = dob.getMonth() + 1;
    const year = dob.getFullYear();
    // return `${day < 10 ? "0" + day : day}-${
    //   month < 10 ? "0" + month : month
    //   }-${year}`;
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  };

  return (
    // <Dialog
    //   className="optCSS"
    //   onClose={handleClose1}
    //   aria-labelledby="customized-dialog-title"
    //   open={open}
    // >
    <BootstrapDialog
      onClose={handleClose1}
      aria-labelledby="customized-dialog-title"
      open={open}
      // fullWidth={fullWidth}
      // maxWidth={maxWidth}
    >
      {/* <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose1}
        sx={{ fontweight: 900 }}
      > */}
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose1}
        sx={{ fontWeight: "800", color: "#148F5B", p: 2 }}
      >
        PAN Verification
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* </BootstrapDialogTitle> */}
      <DialogContent>
        {/* <Grid container spacing={2}> */}
        <Grid item xs={12} mt={1}>
          <Typography>
            Oops! We are not able to verify with the provided PAN number. Please
            enter the details manually for verification.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={adharVerification}
            style={{ height: "20vh", width: "50vw" }}
          />
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Fill up the below details as per given in PAN
          </Typography>
        </Grid>
        {errorInPANDetails ? (
          <Alert severity="error" sx={{ m: 1 }}>
            Please fill in all the required details
          </Alert>
        ) : null}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={12}>
            <TextField
              onChange={(e) => handleChange(e.target.value, "pan")}
              fullWidth
              label="PAN"
              disabled
              value={data}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) => handleChange(e.target.value, "first_name")}
              fullWidth
              label="First Name"
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) => handleChange(e.target.value, "middle_name")}
              fullWidth
              label="Middle Name"
            ></TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) => handleChange(e.target.value, "last_name")}
              fullWidth
              label="Last Name"
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              onChange={(e) => handleChange(e.target.value, "gender")}
              fullWidth
              label="Gender"
            >
              {gender.map((option, key) => (
                <MenuItem
                  key={key}
                  value={option.value}
                  sx={{
                    fontFamily: "'Roboto Condensed', sans-serif",
                    "&:hover": {
                      borderLeft: "5px solid #148F5B",
                      borderRadius: 1,
                    },
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}fullWidth
                label="Date Of Birth"
                onChange={(newValue) => handleChange(newValue, "dob")}
                maxDate={dayjs()}
              />
              {/* <DateField
              fullWidth
              label="Date Of Birth (YYYY-MM-DD)"
              onChange={(newValue) => handleChange(newValue, "dob")}
              defaultValue={dayjs()}
              format="YYYY-MM-DD"
            /> */}
            </LocalizationProvider>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </DialogContent>
      <DialogActions sx={{ mt: 3, mb: 2 }}>
        <Button
          variant="outlined"
          autoFocus
          sx={{
            textTransform: "capitalize",
            fontWeight: "800",
          }}
          onClick={savePANDetails}
        >
          Save Details
        </Button>
      </DialogActions>
      {/* </Dialog> */}
    </BootstrapDialog>
  );
};

export default PanVerification;
