import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { states } from "../BasicDetails/BasicDetailsPages/constant";
import ESignDialog from "./EsignDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  InsuranceDialogView,
  deductFromLoanAmt,
  nomineeRelations,
  addInsurance,
  ESignDialogView,
  GenerateLoanAgreementClearData,
  ESignClearData,
  EStampClearData,
  Status,
  ESignCheckClearData,
  EnachClearData,
  GenerateLoanApplicationClearData,
  GetSanctionLetterClearData,
  GetSanctionLetterStatusClearData,
  GetEsignedLoanApplication,
  insuranceBroch,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Alert,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import { Document, Page, pdfjs } from "react-pdf";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { debounce } from "lodash";
import DownloadIcon from "@mui/icons-material/Download";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
      style={{
        color: "#148F5B",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {children}
      {onClose ? (
        <Typography aria-label="close" onClick={onClose}>
          Close
        </Typography>
      ) : null}
    </DialogTitle>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#148F5B",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function InsuranceDialog(props) {
  const selectedLoanId = props.loanID;
  const Applicationdata = props.data;

  const handleClose = () => {
    setInsuranceError(false);
    dispatch(InsuranceDialogView(!insuranceDialog));
  };

  const handleCloseBrochure = () => {
    setOpenBrochurePopUp(false);
  };

  const NomineeData = {
    loan_id: parseInt(localStorage.getItem("LoanApplicationId"), 10),
    // loan_id: 8802,
    nominee_name: "",
    nominee_dob: "",
    nominee_relation_id: 0,
    nominee_address: {
      houseNo: "",
      street: "",
      locality: "",
      city: "",
      state: "",
    },
    nominee_address_pincode: "",
  };

  const address = {
    houseNo: "",
    street: "",
    locality: "",
    city: "",
    state: "",
  };

  const formatDate = (date) => {
    if (!date) return "";
    const dob = new Date(date);
    const year = dob.getFullYear();
    const month = String(dob.getMonth() + 1).padStart(2, "0");
    const day = String(dob.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [jsonData, setJsonData] = useState(NomineeData);
  const [addressData, setAddressData] = useState(address);
  const [showAlert, setShowAlert] = useState(false);
  const [deduct, setDeduct] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [getCancelled, setGetCancelled] = useState(false);
  const [applicationDetails, setapplicationDetails] = React.useState();
  const [loanapplicationID, setloanapplicationID] = React.useState();
  const [openBrochurePopUp, setOpenBrochurePopUp] = useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [brochurePdfView, setBrochurePdfView] = React.useState();
  const [data, setData] = React.useState();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const DEBOUNCE_DELAY = 1000;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };
  const debouncedNextPage = debounce(handleNextPage, DEBOUNCE_DELAY);
  const debouncedPrevPage = debounce(handlePrevPage, DEBOUNCE_DELAY);

  const handleDownload = () => {
    fetch(brochurePdfView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Insurance_Brochure.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const dispatch = useDispatch();

  const getInsuranceDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getInsuranceDetailsResponse
  );

  const getInsuranceDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.getInsuranceDetailsLoading
  );

  const SaveInsuranceDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.SaveInsuranceDetailsResponse
  );

  const SaveInsuranceDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.SaveInsuranceDetailsLoading
  );

  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );

  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );

  const generateLoanApplicationResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanApplicationResponse
  );

  const getapplicationformDocument = useSelector(
    (state) => state.BasicDetailsReducers.loanApplicationFormData
  );

  const [brochureLoading, setBrochureLoading] = useState(false);

  const [insuranceError, setInsuranceError] = React.useState(false);

  useEffect(() => {
    setCancelled(getCancelled);
  }, [getCancelled]);

  const openNormalLoanApprovalFlow = () => {
    handleClose();
    setCancelled(true);
    console.log(cancelled);
    // setloanapplicationID(localStorage.getItem("LoanApplicationId"));
    dispatch(ESignCheckClearData());
    dispatch(EnachClearData());
    dispatch(GenerateLoanApplicationClearData());
    dispatch(GetSanctionLetterClearData());
    dispatch(
      Status(localStorage.getItem("LoanApplicationId"), handleLogoutButton)
    );
    dispatch(GenerateLoanAgreementClearData());
    dispatch(GetSanctionLetterStatusClearData());
    dispatch(ESignClearData());
    dispatch(EStampClearData());
    dispatch(ESignDialogView(!esiginDialog));
    // setOpenInsuranceDialog(false);
    dispatch(
      GetEsignedLoanApplication(
        localStorage.getItem("LoanApplicationId"),
        handleLogoutButton
      )
    );
    setapplicationDetails(getLoanDetailsResponse);
  };

  const formattedAddress = Object.values(jsonData.nominee_address || {}).join(
    ", "
  );
  const finalData = {
    ...jsonData,
    nominee_address: formattedAddress,
  };

  const confirmInsurance = async () => {
    console.log(cancelled);
    setInsuranceError(true);
    try {
      const response = await dispatch(
        addInsurance(finalData, deduct, handleLogoutButton)
      );
      if (response.data) {
        console.log(SaveInsuranceDetailsResponse);
        if (response?.message == "Insurance added") {
          // setCancelled(false);
          setInsuranceError(false);
          console.log("insurance added");
          // handleClose();
          setCancelled(false);
          setloanapplicationID(localStorage.getItem("LoanApplicationId"));
          dispatch(ESignCheckClearData());
          dispatch(EnachClearData());
          dispatch(GenerateLoanApplicationClearData());
          dispatch(GetSanctionLetterClearData());
          dispatch(
            Status(
              localStorage.getItem("LoanApplicationId"),
              handleLogoutButton
            )
          );
          dispatch(GenerateLoanAgreementClearData());
          dispatch(GetSanctionLetterStatusClearData());
          dispatch(ESignClearData());
          dispatch(EStampClearData());
          dispatch(ESignDialogView(!esiginDialog));
          // setOpenInsuranceDialog(false);
          dispatch(
            GetEsignedLoanApplication(
              localStorage.getItem("LoanApplicationId"),
              handleLogoutButton
            )
          );
          setapplicationDetails(getLoanDetailsResponse);
        } else {
          setCancelled(true);
          setInsuranceError(false);
        }
        console.log(jsonData);
      } else {
        setInsuranceError(false);
      }
    } catch (error) {
      setInsuranceError(false);
    }
  };

  const handleNominee = (e, type) => {
    if (type == "name") {
      const updatedData = { ...jsonData };
      updatedData.nominee_name = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "dob") {
      const date = formatDate(e);
      const updatedData = { ...jsonData };
      updatedData.nominee_dob = date;
      setJsonData(updatedData);
    }
    if (type == "relation") {
      const updatedData = { ...jsonData };
      updatedData.nominee_relation_id = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "houseno") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address.houseNo = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "street") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address.street = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "locality") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address.locality = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "city") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address.city = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "state") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address.state = e.target.value;
      setJsonData(updatedData);
    }
    if (type == "pincode") {
      const updatedData = { ...jsonData };
      updatedData.nominee_address_pincode = e.target.value;
      setJsonData(updatedData);
      if (!/^\d{6}$/.test(e.target.value)) {
        setError(true);
        setHelperText("Pincode must be a 6-digit number");
      } else {
        setError(false);
        setHelperText("");
      }
    }
  };

  useEffect(() => {
    dispatch(nomineeRelations());
    dispatch(
      deductFromLoanAmt(
        localStorage.getItem("LoanApplicationId"),
        0,
        handleLogoutButton
      )
    );
    // dispatch(deductFromLoanAmt(8802, 0, handleLogoutButton));
  }, []);

  const generateInsuranceBrochure = async () => {
    //open pop up only when doc is downloaded else show loader
    setOpenBrochurePopUp(true);
    setBrochureLoading(true);
    try {
      const response = await insuranceBroch(
        localStorage.getItem("LoanApplicationId")
      );
      // GetInsuranceBrochure
      setBrochureLoading(false);
      console.log(response?.data?.data?.file_data);
      const base64Content = response?.data?.data?.file_data;
      const base64Data = base64Content;
      const pdfData = atob(base64Data);
      const uint8Array = new Uint8Array(pdfData.length);
      for (let i = 0; i < pdfData.length; i++) {
        uint8Array[i] = pdfData.charCodeAt(i);
      }
      const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setData(pdfUrl);
      setBrochurePdfView(pdfUrl);
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      console.log("Generated Blob URL:", pdfUrl);
    } catch (error) {}
  };

  const relation = useSelector((state) => state.BasicDetailsReducers.relation);

  console.log(getInsuranceDetailsResponse);

  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  //--------------------------------------------------------------
  const insuranceDialog = useSelector(
    (state) => state.BasicDetailsReducers.insuranceDialog
  );

  const handleChange = (event) => {
    const deductBool = event.target.value === "true";
    setDeduct(deductBool);
  };

  useEffect(() => {
    console.log(typeof deduct);
  }, [deduct]);

  return (
    <div>
      <Dialog
        fullScreen
        open={insuranceDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Insurance Application
            </Typography>
            <Button
              autoFocus
              color="inherit"
              sx={{ textTransform: "capitalize" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={deduct}
            onChange={handleChange}
          >
            <Grid item xs={1} sx={{ mt: 2 }}></Grid>
            <Grid item xs={12} sx={{ ml: 10, mr: 10, mt: 2 }}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      sx={{
                        color: "#148F5B",
                        "&.Mui-checked": { color: "#148F5B" },
                      }}
                    />
                  }
                  label="Option 1: Top Up to the Loan Amount"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: 700,
                      color: "inherit",
                    },
                    "& .Mui-checked + .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                      color: "#148F5B",
                    },
                  }}
                />
                {/* <Typography sx={{ mt: 1 }}>
                Option 1: Top Up to Loan Amount
              </Typography> */}
              </div>
            </Grid>
            <Typography sx={{ ml: 10, mr: 10 }}>
              Top up loan with Premium amount; Revised EMI amount:
            </Typography>
            <Grid item xs={12} sx={{ ml: 10, mr: 10, mt: 2, mb: 5 }}>
              {/* <InsuranceTable /> */}
              {getInsuranceDetailsResponse.length !== 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ fontWeight: 900 }}>
                          Loan ID
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          New EMI
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Total Insurance Premium Amount (including GST)
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          New Loan Amount
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Tenure
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Interest
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {getInsuranceDetailsResponse != undefined ||
                    getInsuranceDetailsResponse.length !== 0 ? (
                      <TableBody sx={{ p: 2 }}>
                        {/* {getInsuranceDetailsResponse.map((Insurancedetails) => ( */}
                        <StyledTableRow sx={{ p: 2 }}>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            component="th"
                            scope="row"
                          >
                            {/* {Insurancedetails?.id} */}
                            {localStorage.getItem("LoanApplicationId")}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.topup?.emi?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.topup?.insurance_amount?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.topup?.loan_amount?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.topup?.tenure}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {
                              getInsuranceDetailsResponse?.data?.topup
                                ?.interest_rate
                            }
                            %
                          </StyledTableCell>
                        </StyledTableRow>
                        {/* ))} */}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} sx={{ ml: 10, mr: 10, mt: 2 }}>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      sx={{
                        color: "#148F5B",
                        "&.Mui-checked": { color: "#148F5B" },
                      }}
                    />
                  }
                  label="Option 2: Deduct Insurance From Loan Amount"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: 700,
                      color: "inherit",
                    },
                    "& .Mui-checked + .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                      color: "#148F5B",
                    },
                  }}
                />
                {/* <Typography sx={{ mt: 1 }}>
                Option 2: Deduct Insurance From Loan Amount
              </Typography> */}
              </div>
            </Grid>
            <Typography sx={{ ml: 10, mr: 10 }}>
              Deduct from approved loan amount. No change in EMI. Loan will be
              disbursed net of premium amount.
            </Typography>
            <Grid item xs={12} sx={{ ml: 10, mr: 10, mt: 2, mb: 5 }}>
              {/* <InsuranceTable /> */}
              {getInsuranceDetailsResponse.length !== 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ fontWeight: 900 }}>
                          Loan ID
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          New EMI
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Total Insurance Premium Amount (including GST)
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          New Loan Amount
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Tenure
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Interest
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {getInsuranceDetailsResponse != undefined ||
                    getInsuranceDetailsResponse.length !== 0 ? (
                      <TableBody sx={{ p: 2 }}>
                        {/* {getInsuranceDetailsResponse.map((Insurancedetails) => ( */}
                        <StyledTableRow sx={{ p: 2 }}>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            component="th"
                            scope="row"
                          >
                            {/* {Insurancedetails?.id} */}
                            {localStorage.getItem("LoanApplicationId")}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.deducted?.emi?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.deducted?.insurance_amount?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {getInsuranceDetailsResponse?.data?.deducted?.loan_amount?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {
                              getInsuranceDetailsResponse?.data?.deducted
                                ?.tenure
                            }
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ fontWeight: 900 }}
                            align="center"
                          >
                            {
                              getInsuranceDetailsResponse?.data?.deducted
                                ?.interest_rate
                            }
                            %
                          </StyledTableCell>
                        </StyledTableRow>
                        {/* ))} */}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                </>
              )}
            </Grid>

            <Grid item xs={12} sx={{ ml: 10, mr: 10, mt: 2 }}>
              <div style={{ display: "flex" }}>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#148F5B",
                    fontWeight: "800",
                  }}
                >
                  Nominee Details
                </Typography>
              </div>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="Name"
                    id="name"
                    required
                    onChange={(event) => handleNominee(event, "name")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Birth"
                      onChange={(event) => handleNominee(event, "dob")}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          fullWidth: true, // Ensures it takes full width of the parent Grid
                          sx: {
                            "> .MuiOutlinedInput-root": {
                              height: 47,
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    fullWidth
                    label="Relation"
                    name="Relation"
                    id="relation"
                    required
                    onChange={(event) => handleNominee(event, "relation")}
                  >
                    {relation?.data?.map((option) => (
                      <MenuItem
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                        key={option.id}
                        value={option.id}
                      >
                        {option.relation}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Address</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="House No"
                    name="House No"
                    id="houseno"
                    onChange={(event) => handleNominee(event, "houseno")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Street"
                    name="Street"
                    id="street"
                    onChange={(event) => handleNominee(event, "street")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Locality"
                    name="Locality"
                    id="locality"
                    onChange={(event) => handleNominee(event, "locality")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="City"
                    name="City"
                    id="city"
                    onChange={(event) => handleNominee(event, "city")}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    onChange={(event) => handleNominee(event, "state")}
                    fullWidth
                    label="State"
                  >
                    {states.map((state, key) => (
                      <MenuItem
                        key={key}
                        value={state}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    name="Pincode"
                    id="pincode"
                    // value={nomineeDetails.pincode}
                    onChange={(event) => handleNominee(event, "pincode")}
                    error={error} // Apply error state
                    helperText={helperText} // Display error message
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2, mr: 10 }}>
              <Grid item xs={12} sm={8} md={8}></Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  onClick={openNormalLoanApprovalFlow}
                  variant="outlined"
                  sx={{ textTransform: "capitalize", width: "100%" }}
                >
                  Cancel Insurance Application
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={confirmInsurance}
                  variant="contained"
                  sx={{ textTransform: "capitalize", width: "100%" }}
                  disabled={
                    insuranceError ||
                    getInsuranceDetailsResponse.length === 0 ||
                    jsonData.nominee_name === "" ||
                    jsonData.nominee_dob === "" ||
                    jsonData.nominee_address === "" ||
                    jsonData.nominee_address_pincode === "" ||
                    jsonData.nominee_relation_id === "" ||
                    jsonData.nominee_relation_id === 0 ||
                    deduct === null ||
                    error
                  }
                  startIcon={
                    insuranceError ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  Confirm Insurance
                </Button>
              </Grid>
              {cancelled ? (
                <>
                  {esiginDialog ? (
                    <ESignDialog
                      loanID={selectedLoanId}
                      data={Applicationdata}
                      insurance={false}
                      applied={false}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {esiginDialog ? (
                    <ESignDialog
                      loanID={selectedLoanId}
                      data={Applicationdata}
                      insurance={true}
                      applied={true}
                    />
                  ) : null}
                </>
              )}
            </Grid>
          </RadioGroup>
          <Grid
            container
            xs={12}
            sx={{ mt: 3 }}
            style={{ backgroundColor: "#148F5B", borderRadius: "8px" }}
          >
            <Grid item xs={8} sx={{ p: 3, mt: 2, ml: 10, mr: 10 }}>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div style={{ display: "flex", color: "white", gap: "15px" }}>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: 700,
                      fontSize: "1.15rem",
                    }}
                  >
                    HospiCash:
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    An insurance that provides you up to Rs.1000/- per day for
                    Hospitalization.**
                  </Typography>
                </div>
                <div style={{ display: "flex", color: "white", gap: "15px" }}>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: 700,
                      fontSize: "1.15rem",
                    }}
                  >
                    EMI Protect:
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    An insurance that protects up to 3 EMIs in a Policy Year
                    when you are Hospitalized and eligible for HospiCash.**
                  </Typography>
                </div>
                <div style={{ display: "flex", color: "white", gap: "15px" }}>
                  <Typography
                    sx={{
                      minWidth: "100px",
                      fontWeight: 700,
                      fontSize: "1.15rem",
                    }}
                  >
                    Credit Shield:
                  </Typography>
                  <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
                    An insurance that covers entire Loan amount in the
                    unfortunate event death of the Insured Client.**
                  </Typography>
                </div>
              </div>
              <Typography
                style={{
                  color: "white",
                  display: "flex",
                  marginTop: "20px",
                  fontSize: "1.09rem",
                }}
              >
                <b>**</b>Terms & Conditions Apply. Read product details
                carefully before applying.&nbsp;
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                backgroundColor: "#1C6D45", // Darker green background for contrast
                margin: 3,
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Want to know more? Click below
              </Typography>
              <Button
                onClick={() => generateInsuranceBrochure()}
                style={{
                  marginTop: "15px",
                  padding: "12px 30px",
                  backgroundColor: "#ffffff",
                  color: "#148F5B",
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: "600",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          {openBrochurePopUp ? (
            <>
              <BootstrapDialog
                className="brochureCSS"
                onClose={handleCloseBrochure}
                aria-labelledby="customized-dialog-title"
                open={openBrochurePopUp}
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleCloseBrochure}
                  style={{
                    fontWeight: "800",
                  }}
                >
                  Insurance Brochure
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  {brochureLoading ? (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{ border: "1px solid #000000", mt: 1 }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            height: "450px",
                            // width: "600px",
                            overflow: "auto",
                          }}
                        >
                          <div style={{ height: "500px" }}>
                            <Document
                              file={data}
                              onLoadSuccess={onDocumentLoadSuccess}
                              options={{ workerSrc: "/pdf.worker.js" }}
                            >
                              <Page
                                pageNumber={pageNumber}
                                renderMode="canvas"
                                renderTextLayer={false}
                              />
                            </Document>
                          </div>
                          {numPages && (
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          )}
                        </div>
                        {numPages && (
                          <Grid container>
                            <Grid item xs={12} md={9}>
                              <div style={{ width: "600px" }}>
                                <Button
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={debouncedPrevPage}
                                  disabled={pageNumber === 1}
                                >
                                  Previous Page
                                </Button>
                                <Button
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={debouncedNextPage}
                                  disabled={pageNumber === numPages}
                                >
                                  Next Page
                                </Button>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Button
                                endIcon={<DownloadIcon />}
                                onClick={handleDownload}
                                sx={{ textTransform: "capitalize" }}
                              >
                                Download Brochure
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </DialogContent>
              </BootstrapDialog>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      {/* {esiginDialog ? (<>
        {cancelled ? (
          <ESignDialog loanID={localStorage.getItem("LoanApplicationId")} data={applicationDetails} insurance={false} />
        ) : (
          <ESignDialog loanID={localStorage.getItem("LoanApplicationId")} data={applicationDetails} insurance={true} />
        )}</>
      ) : null} */}

      {/* {cancelled ? (
        <>{esiginDialog? (<ESignDialog loanID={localStorage.getItem("LoanApplicationId")} data={applicationDetails} insurance={true} />) : null}
        </>) : (<>
          {esiginDialog ? (<ESignDialog loanID={localStorage.getItem("LoanApplicationId")} data={applicationDetails} insurance={true} />) : null}
        </>)
      } */}
    </div>
  );
}
