import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';

function GreyLoadingScreen() {
  const [loading, setLoading] = useState(true);

  return (
    <div>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(250, 250, 250, 0.5)', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1300,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
    </div>
  );
}

export default GreyLoadingScreen;
