import TYPES from "../BasicDetailsTypes/BasicDetailsTypes";
import { base_url } from "../../../constants";
import { base_url2 } from "../../../constants";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { genInsight_base_url } from "../../../constants";
import { TrySharp } from "@mui/icons-material";

//------------------------- Aadhar Drawer --------------------

export const AadharDrawer = (status) => (dispatch) => {
  dispatch({
    type: TYPES.AADHAR_DRAWER,
    payload: status,
  });
};

//------------------------- PAN Drawer --------------------

export const PANDrawer = (status) => (dispatch) => {
  dispatch({
    type: TYPES.PAN_DRAWER,
    payload: status,
  });
};

//-----------------------KYC Details --------------------------

export const sendkycToAPI = (loan_id, list) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  const body = {
    loan_id: loan_id,
    user_frames: list,
  };
  Axios.post(`${base_url2}/postSaf/submitVideoKyc`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.SEND_KYC_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: TYPES.SEND_KYC_FAIL,
        });
      }
    });
};

//------------------------- SAF Post API ----------- > AF: Short Application Form : User Inputs to the API  --------------------

export const SAF =
  (
    employmentType,
    pan,
    aadhaar,
    loanProduct,
    loanAmount,
    loanTenure,
    emailAddress,
    mobileNumber,
    dateofbirth,
    panConformation,
    aadhaarConformation,
    beauroScoreConformation,
    valueOfCollateral,
    CIN,
    aadharDetails,
    maritalStatusSelected,
    hasOwnPropertySelected,
    panDetails,
    data,
    employeeID,
    shopName,
    shopAddress,
    landmark,
    mothersName,
    fathersName,
    callback
  ) =>
  (dispatch) => {
    let branch_name = localStorage.getItem("branch");
    let propertySelected = "";
    if (branch_name == "null") {
      var branch = "Head Office";
    } else {
      var branch = branch_name;
    }
    if (panConformation === true) {
      var panString = "Y";
    } else {
      var panString = "N";
    }

    if (aadhaarConformation === true) {
      var aadhaarString = "Y";
    } else {
      var aadhaarString = "N";
    }

    if (beauroScoreConformation === true) {
      var beauroScoreString = "Y";
    } else {
      var beauroScoreString = "N";
    }
    if (CIN == undefined) {
      CIN = "";
    }
    if (hasOwnPropertySelected == "true") {
      propertySelected = true;
    }
    if (hasOwnPropertySelected == "false") {
      propertySelected = false;
    }

    dispatch(SAFLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    localStorage.setItem("employmentType", employmentType);
    localStorage.setItem("loanProduct", loanProduct);
    dispatch({
      type: TYPES.IDENTIFY_EMPLOYMENT_TYPE,
      payload: employmentType,
    });
    dispatch({
      type: TYPES.IDENTIFY_LOANPRODUCT_TYPE,
      payload: loanProduct,
    });
    const email = localStorage.getItem("EmailId");
    if (employmentType == 1 || employmentType == 2 || employmentType == 7) {
      var aadharData;
      if (aadharDetails.data == undefined) {
        aadharData = "";
      } else {
        aadharData = aadharDetails.data;
      }
      const body = {
        EmploymentTypeId: employmentType,
        LoanProductId: loanProduct,
        PAN: pan,
        Aadhar: aadhaar,
        aadhar_verify: "YES",
        LoanAmount: parseInt(loanAmount),
        LoanTenure: parseInt(loanTenure),
        Aadhar_card_status: "VALID",
        EmailId: email,
        email_id: email,
        Phone: mobileNumber,
        DateofBirth: dateofbirth,
        valueOfCollateral: valueOfCollateral,
        pan_consent: "Y",
        pan_details: panDetails,
        aadhar_details: aadharData,
        branch: branch,
        emp_code: employeeID,
        marital_status: maritalStatusSelected,
        own_house: propertySelected,
        shop_name: shopName,
        shop_address: shopAddress,
        landmark: landmark,
        mothers_name: mothersName,
        fathers_name: fathersName,
        coApplicantData: data,
        Consent: [
          {
            ConsentType: "PAN",
            ConsentValue: "Y",
          },
          {
            ConsentType: "Aadhaar",
            ConsentValue: "Y",
          },
          {
            ConsentType: "CreditBureau",
            ConsentValue: "Y",
          },
        ],
      };
      Axios.post(`${base_url2}/v3/submitSaf`, body, config)
        .then((response) => {
          localStorage.setItem(
            "LoanApplicationId",
            response.data.data.LoanApplicationId
          );
          dispatch({
            type: TYPES.SAF_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            if (callback) {
              callback();
              dispatch({
                type: TYPES.SAF_FAIL,
              });
            }
          } else {
            dispatch({
              type: TYPES.SAF_FAIL,
            });
          }
        });
    } else {
      const body = {
        EmploymentTypeId: employmentType,
        LoanProductId: loanProduct,
        PAN: pan,
        pan_details: panDetails,
        Aadhar: aadhaar,
        LoanAmount: parseInt(loanAmount),
        LoanTenure: parseInt(loanTenure),
        emailid: email,
        EmailId: email,
        email_id: email,
        Phone: mobileNumber,
        DateofBirth: dateofbirth,
        valueOfCollateral: valueOfCollateral,
        CIN: CIN,
        aadhar_verify: "YES",
        pan_consent: "Y",
        aadhar_details: "",
        branch: branch,
        emp_code: employeeID,
        marital_status: maritalStatusSelected,
        own_house: propertySelected,
      };
      Axios.post(`${base_url2}/v3/submitSaf`, body, config)
        .then((response) => {
          localStorage.setItem(
            "LoanApplicationId",
            response.data.data.LoanApplicationId
          );
          dispatch({
            type: TYPES.SAF_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            if (callback) {
              callback();
              dispatch({
                type: TYPES.SAF_FAIL,
              });
            }
          } else {
            dispatch({
              type: TYPES.SAF_FAIL,
            });
          }
        });
    }
  };

//------------------------- SAF Post API Loading  --------------------

export const SAFLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAF_LOADING,
  });
};

//------------------------- Quick Apply Post API -------------------------------

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const dummyQuickApply = () => (dispatch) => {
  dispatch(QuickApplyLoading());
  dispatch({
    type: TYPES.QUICK_APPLY_SUCCESS,
    payload: {
      response_data: {
        jwt_token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbF9pZCI6InUyMDAzMDExQHJhamFnaXJpLmVkdS5pbiIsImJyYW5jaCI6IkVSTkFLVUxBTSIsInJvbGUiOiJjdXN0b21lciIsImV4cGlyeV90aW1lIjoiMjAyNC0wMy0yOCAxMzoyNjo1OCJ9.Cxo8g_9amXR4uaJWUTYKO-n6TcGB_epEUPeY9qrBuWs",
        loan_details: {
          LoanApplicationId: 4125,
          LoanStatus: "APPROVED",
          RefernceNumber: "SAF_2024032812265226_4125",
        },
      },
      response_message: "SUCCESS",
    },
  });
  sleep(5000);
  console.log("sleep done");
};

export const QuickApply =
  (
    pan,
    pandetails,
    aadharNo,
    loanamt,
    emailId,
    aadhardetails,
    isaadharChecked,
    ispanchecked
  ) =>
  async (dispatch) => {
    if (isaadharChecked && ispanchecked) {
      var cons = [
        {
          ConsentType: "PAN",
          ConsentValue: "Y",
        },
        {
          ConsentType: "Aadhaar",
          ConsentValue: "Y",
        },
      ];
    } else {
      var cons = [
        {
          ConsentType: "PAN",
          ConsentValue: "N",
        },
        {
          ConsentType: "Aadhaar",
          ConsentValue: "N",
        },
      ];
    }
    dispatch(QuickApplyLoading());
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = {
      PAN: pan,
      pan_details: pandetails,
      Aadhaar: aadharNo,
      LoanAmount: parseInt(loanamt),
      email_id: emailId,
      aadhaar_details: aadhardetails,
      //Consent: cons
    };
    try {
      const response = await Axios.post(
        `${base_url2}/quickApply/submit`,
        body,
        config
      );
      if (response.data) {
        dispatch({
          type: TYPES.QUICK_APPLY_SUCCESS,
          payload: response.data,
        });
        return response.data;
      }
    } catch (error) {
      dispatch({
        type: TYPES.QUICK_APPLY_FAIL,
      });
      return false;
    }
    // .then((response) => {
    //     localStorage.setItem(
    //       "LoanApplicationId",
    //       response.data.data.LoanApplicationId
    //       );
    //     dispatch({
    //       type: TYPES.QUICK_APPLY_SUCCESS,
    //       payload: response.data,
    //     });
    //     return true;
    //   })
    //   .catch((error) => {
    //     if (error.response.status == 401) {
    //       dispatch({
    //         type: TYPES.SAF_FAIL,
    //       });
    //     }
    //   });
  };

//------------------------- Quick Apply API Loading  --------------------

export const QuickApplyLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.QUICK_APPLY_LOADING,
  });
};

//------------------------- SAF Post API Clear Data-------------------

export const SAFClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAF_CLEAR_DATA,
  });
};

//-------------------------  Company Details   --------------------

export const CompanyDetails = (cin) => (dispatch) => {
  if (cin != undefined) {
    dispatch(CompanyDetailsLoading());
    Axios.get(`${base_url2}/company_info/${cin}`)
      .then((response) => {
        dispatch({
          type: TYPES.COMPANY_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.COMPANY_DETAILS_FAIL,
        });
      });
  }
};

//------------------------- Company Details Loading  --------------------

export const CompanyDetailsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPANY_DETAILS_LOADING,
  });
};
export const guarantorDetailsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GUARANTOR_DETAILS_LOADING,
  });
};
//------------------------- Company Details  Clear Data-------------------

export const CompanyDetailsClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPANY_DETAILS_CLEAR_DATA,
  });
};

//-------------------------  Get Quick apply details for ETB  --------------------

export const getQuickapplydetails = (token) => (dispatch) => {
  dispatch(QuickapplydetailsLoading());
  Axios.get(`${base_url2}/quickApply/getCustomerDetails?apply_token=${token}`)
    .then((response) => {
      dispatch({
        type: TYPES.QUICK_APPLY_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.QUICK_APPLY_DETAILS_FAIL,
      });
    });
};

//------------------------- Company Details Loading  --------------------

export const QuickapplydetailsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.QUICK_APPLY_DETAILS_LOADING,
  });
};

//-------------------------  Save Details   --------------------

export const SaveDetails =
  (
    loan_application_id,
    address_line1,
    address_line2,
    city,
    country_of_incorporation,
    date_of_incorporation,
    industry_type,
    land_mark,
    name_of_organization,
    operating_since,
    pincode,
    place_of_incorporation,
    registration_number,
    state,
    type_of_organization,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(SaveDetailsLoading());
    const body = {
      loan_application_id: loan_application_id,
      address_line1: address_line2,
      address_line2: "",
      city: city,
      country_of_incorporation: country_of_incorporation,
      date_of_incorporation: date_of_incorporation,
      industry_type: industry_type,
      land_mark: land_mark,
      name_of_organization: name_of_organization,
      operating_since: operating_since,
      pincode: pincode,
      place_of_incorporation: place_of_incorporation,
      registration_number: registration_number,
      state: state,
      type_of_organization: type_of_organization,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(`${base_url2}/save_company_data`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.SAVE_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
          dispatch({
            type: TYPES.SAVE_DETAILS_FAIL,
          });
        }
      });
  };

//-------------------------  Save Details Loading  --------------------

export const SaveDetailsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_DETAILS_LOADING,
  });
};

//-------------------------  Save Details  Clear Data-------------------

export const SaveDetailsClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_DETAILS_CLEAR_DATA,
  });
};

//-------------------------  Aadhar Verification  - Initiate_Session  --------------------

export const InitiateSession = () => async (dispatch) => {
  dispatch(InitiateSessionLoading());
  try {
    const response = await Axios.get(`${base_url2}/Initiate_Session`);
    dispatch({
      type: TYPES.INITIATE_SESSION_AADHAR_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.INITIATE_SESSION_AADHAR_FAIL,
    });
  }
};

//------------------------- Aadhar Verification  - Initiate_Session Loading  --------------------

export const InitiateSessionLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.INITIATE_SESSION_AADHAR_LOADING,
  });
};

//------------------------- Aadhar Verification  - Initiate_Session  Clear Data-------------------

export const InitiateSessionClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.INITIATE_SESSION_AADHAR_CLEAR_DATA,
  });
};

//------------------------- GenOTP   --------------------

export const GenOTP =
  (aadhar_number, captcha, session_id) => async (dispatch) => {
    dispatch(GenOTPLoading());
    const body = {
      aadhar_number: aadhar_number,
      captcha: captcha,
      session_id: session_id,
    };
    try {
      const response = await Axios.post(`${base_url2}/generate_otp`, body);
      dispatch({
        type: TYPES.GEN_OTP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.GEN_OTP_FAIL,
      });
    }
  };

//-------------------------  GenOTP Loading  --------------------

export const GenOTPLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GEN_OTP_LOADING,
  });
};

//-------------------------  GenOTP  Clear Data-------------------

export const GenOTPClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GEN_OTP_CLEAR_DATA,
  });
};
export const InitiateOTPClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.INITIATE_SESSION_AADHAR_CLEAR_DATA,
  });
};
//------------------------- Verify OTP   --------------------

export const VerifyOTP =
  (otp, session_id, isCoBorrower) => async (dispatch) => {
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    dispatch(VerifyOTPLoading());
    const body = {
      loan_id: loanApplicationId,
      otp: otp,
      session_id: session_id,
      is_coborrower: isCoBorrower,
    };

    try {
      const response = await Axios.post(
        `${base_url2}/verify_otp_get_response`,
        body
      );
      dispatch({
        type: TYPES.VERIFY_OTP_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.VERIFY_OTP_FAIL,
      });
    }
  };

//-------------------------  Verify OTP Loading  --------------------

export const VerifyOTPLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_OTP_LOADING,
  });
};

//-------------------------  Verify OTP  Clear Data-------------------

export const VerifyOTPClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_OTP_CLEAR_DATA,
  });
};

//------------------------- Save Aadhar   --------------------
export const VerifyOTPClearDataCoborrower = () => (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_OTP_COBORROWER_CLEAR_DATA,
  });
};
export const SaveAadhar =
  (
    company,
    pan,
    loan_application_id,
    items,
    loanProductOption,
    loanAmount,
    employmentTypeValue,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(SaveAadharLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = {
      loan_application_id: loan_application_id,
      company_id: company,
      company_pan: pan,
      items: items,
      LoanProductId: loanProductOption,
      LoanAmount: loanAmount,
      EmploymentTypeId: employmentTypeValue,
    };
    Axios.post(
      `${base_url2}/create_modify_company_director_details`,
      body,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.SAVE_AADHAR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
          dispatch({
            type: TYPES.SAVE_AADHAR_FAIL,
          });
        }
      });
  };
function convertFirstJsonToSecondJson(
  guarantorDetails,
  loan_application_id,
  loanAmount,
  loanProductOption
) {
  return guarantorDetails.map((item) => ({
    id: 0,
    loan_id: loan_application_id,
    full_name: item.full_name,
    aadhar: item.aadhar || "null",
    address_line1: item.address_line1,
    address_line2: item.address_line2 || "null",
    landmark: item.landmark || "null",
    city: item.city,
    pincode: item.pincode,
    state: item.state,
    aadhar_verification: item.aadhar_verification || "null",
    PAN: item.PAN,
    DocumentType: item.documentType,
    LoanProductId: loanProductOption,
    LoanAmount: loanAmount,
  }));
}
function FileUpload(guarantorDetails, data, loan_application_id) {
  const updatedSecondJson = guarantorDetails.map((obj) => {
    const matchingObj = data.find((item) => item.full_name === obj.full_name);
    return {
      id: matchingObj ? matchingObj.id : obj.id,
      loan_id: loan_application_id,
      file: obj.file,
      DocType: obj.documentType,
    };
  });
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  updatedSecondJson.map((obj) => {
    if (obj.DocType == "Bank Statement") {
      let data = null;
      data = new FormData();
      data.append("LoanApplicationId", loan_application_id);
      data.append("GuarantorBankStatement", obj.file);
      data.append("loan_guarantor_id", obj.id);
      data.append("document_comment", "Testing");
      Axios.post(`${base_url2}/UploadFileNew`, data, config).then(
        (response) => {}
      );
    } else {
      let data = null;
      data = new FormData();
      data.append("LoanApplicationId", loan_application_id);
      data.append("GuarantorITR", obj.file);
      data.append("loan_guarantor_id", obj.id);
      data.append("document_comment", "Testing");
      Axios.post(`${base_url2}/UploadFileNew`, data, config).then(
        (response) => {}
      );
    }
  });
}
export const saveguarantorDetails =
  (
    loan_application_id,
    guarantorDetails,
    loanAmount,
    loanProductOption,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(guarantorDetailsLoading());
    const details = convertFirstJsonToSecondJson(
      guarantorDetails,
      loan_application_id,
      loanAmount,
      loanProductOption
    );
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(`${base_url2}/create_modify_loan_guarantor`, details, config)
      .then((response) => {
        dispatch({
          type: TYPES.GUARANTOR_DETAILS_SUCCESS,
          payload: response.data,
        });
        FileUpload(guarantorDetails, response.data.data, loan_application_id);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
          dispatch({
            type: TYPES.GUARANTOR_DETAILS_FAIL,
          });
        }
      });
  };
export const getRepaymentSchedule1 =
  (loan_id, loan_amount, interest_rate, loantenure, navigateCallback) =>
  (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(
      `${base_url2}/getmonthlyemi`,
      {
        loan_id: parseInt(loan_id),
        loan_amount: parseInt(loan_amount),
        loantenure: parseInt(loantenure),
        interest_rate: parseInt(interest_rate),
      },
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.REPAYMENT_DETAILS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.REPAYMENT_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
export const loanUserDecision =
  (id, decision, reason, navigateCallback) => () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(
      `${base_url2}/loan_user_decision`,
      {
        id: id,
        user_decision: decision,
        reason: reason,
      },
      config
    )
      .then((response) => {})
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

export const saveRegistrationDetails = async (registrationDetails, branch) => {
  const body = {
    email_id: registrationDetails.email,
    login_password: registrationDetails.password,
    user_name: registrationDetails.username,
    mobile_no: registrationDetails.mobileNo,
    branch_name: branch[0].Branch_Name,
    branch_code: branch[0].Branch_Code,
  };
  try {
    const response = await Axios.post(`${base_url2}/user_register`, body);

    // if (
    //   response.data.data[0].Result == "The Email Id exists with DLA System!" ||
    //   response.data.data[0].Result ==
    //     "The Mobile Number exists within DLA System!"
    // ) {
    //   return 0;
    // }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const saveguarantorFileUpload =
  (loan_application_id, event, type) => (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const loanApplicationId = loan_application_id;
    let data = null;
    data = new FormData();
    data.append("LoanApplicationId", loanApplicationId);
    data.append(type, event.target.files[0]);
    data.append("document_comment", "Testing");
    Axios.post(`${base_url2}/UploadFileNew`, data, config).then(
      (response) => {}
    );
  };
//-------------------------  Save Aadhar Loading  --------------------

export const SaveAadharLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_AADHAR_LOADING,
  });
};

//-------------------------  Save Aadhar  Clear Data-------------------

export const SaveAadharClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_AADHAR_CLEAR_DATA,
  });
};

//-------------------------  Confirm All Dilaog -------------------

export const ConfirmAllDilaog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.CONFIRM_DIALOG,
    payload: status,
  });
};

//-------------------------  Topbar View Steps -------------------

export const TopbarViewSteps = (step) => (dispatch) => {
  dispatch({
    type: TYPES.TOPBAR_VIEW_STEP,
    payload: step,
  });
};

//-------------------------  Upload Document API ->Step 3  --------------------

export const UploadDocument =
  (
    bankStatements,
    financailStatemnt,
    gstDocument,
    collateralDocument,
    salaryStatements,
    itrStatements,
    bankName1,
    bankName2,
    bankName3,
    bankName4,
    bankName5,
    bankName6,
    bankStatement1,
    bankStatement2,
    bankStatement3,
    bankStatement4,
    bankStatement5,
    bankStatement6,
    autodebit,
    password1,
    password2,
    password3,
    password4,
    password5,
    password6,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(UploadDocumentLoading());
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    let data = null;
    data = new FormData();
    data.append("LoanApplicationId", loanApplicationId);
    data.append("Autodebit", autodebit);
    for (const key in collateralDocument) {
      if (Object.hasOwnProperty.call(collateralDocument, key)) {
        data.append(`collateral_multi`, collateralDocument[key]);
      }
    }
    data.append("financailStatemnt", financailStatemnt);
    data.append("FinancialDocument1", financailStatemnt);
    data.append("document_comment", "Testing");
    data.append("BankName1", bankName1);
    data.append("BankStatement1", bankStatement1);
    if (password1 != undefined) {
      data.append("BankStatement1_password", password1);
    }
    if (bankName2 && bankStatement2) {
      data.append("BankName2", bankName2);
      data.append("BankStatement2", bankStatement2);
      if (password2 != undefined) {
        data.append("BankStatement2_password", password2);
      }
    }
    if (bankName3 && bankStatement3) {
      data.append("BankName3", bankName3);
      data.append("BankStatement3", bankStatement3);
      if (password3 != undefined) {
        data.append("BankStatement3_password", password3);
      }
    }
    if (bankName4 && bankStatement4) {
      data.append("BankName4", bankName4);
      data.append("BankStatement4", bankStatement4);
      if (password4 != undefined) {
        data.append("BankStatement4_password", password4);
      }
    }
    if (bankName5 && bankStatement5) {
      data.append("BankName5", bankName5);
      data.append("BankStatement5", bankStatement5);
      if (password5 != undefined) {
        data.append("BankStatement5_password", password5);
      }
    }
    if (bankName6 && bankStatement6) {
      data.append("BankName6", bankName6);
      data.append("BankStatement6", bankStatement6);
      if (password6 != undefined) {
        data.append("BankStatement6_password", password6);
      }
    }

    // for (const key in bankStatements) {
    //   if (Object.hasOwnProperty.call(bankStatements, key)) {
    //     data.append(`BankStatement${parseInt(key) + 1}`, bankStatements[key]);
    //   }
    // }
    for (const key in salaryStatements) {
      if (Object.hasOwnProperty.call(salaryStatements, key)) {
        data.append(`PaySlip${parseInt(key) + 1}`, salaryStatements[key]);
      }
    }
    for (const key in itrStatements) {
      if (Object.hasOwnProperty.call(itrStatements, key)) {
        data.append(`ITR${parseInt(key) + 1}`, itrStatements[key]);
      }
    }

    for (const key in gstDocument) {
      if (Object.hasOwnProperty.call(gstDocument, key)) {
        data.append(`GSTN${parseInt(key) + 1}`, gstDocument[key]);
      }
    }
    Axios.post(`${base_url2}/UploadFileNew`, data, config)
      .then((response) => {
        dispatch({
          type: TYPES.UPLOAD_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
          dispatch({
            type: TYPES.UPLOAD_DOCUMENT_FAIL,
          });
        }
      });
  };

//------------------------- Upload Document API ->Step 3   --------------------

export const UploadDocumentLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_DOCUMENT_LOADING,
  });
};

//-------------------------  Upload Document API ->Step 3  -------------------

export const UploadDocumentClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_DOCUMENT_CLEAR_DATA,
  });
};

//------ Upload Dilaog  --------------

export const UploadDilaogView = (status) => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_DIALOG,
    payload: status,
  });
};

//-------------------------  Collateral Save  --------------------

export const CollateralSave =
  (
    TypeofProperty,
    DeveloperName,
    ProjectName,
    BuiltUpArea,
    PurchasePrice,
    APF,
    UnderConstruction,
    CompletePercentage,
    AgeofProperty,
    AddressLine,
    Landmark,
    City,
    PinCode,
    State,
    navigateCallback
  ) =>
  (dispatch) => {
    dispatch(CollateralSaveLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    const body = {
      loan_id: loanApplicationId,
      type_of_property: TypeofProperty,
      financial_year: "2022-2023",
      developer_name: DeveloperName,
      project_name: ProjectName,
      built_up_area: BuiltUpArea,
      purchase_price: PurchasePrice,
      apf: APF,
      ownership_of_property: "",
      under_construction: UnderConstruction,
      completion_percentage: CompletePercentage,
      age_of_property: AgeofProperty,
      expected_date_of_completion: "",
      year_of_construction_completed: "",
      AddressLine: AddressLine,
      Landmark: Landmark,
      City: City,
      State: State,
      Pincode: PinCode,
    };
    Axios.post(
      `${base_url2}/dla/create_modify_collateral_details`,
      body,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.COLLATERAL_SAVE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.COLLATERAL_SAVE_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//------------------------- Collateral Save Loading   --------------------

export const CollateralSaveLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.COLLATERAL_SAVE_FAIL,
  });
};

//-------------------------   Collateral Save Clear Data  -------------------

export const CollateralSaveClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.COLLATERAL_SAVE_CLEAR_DATA,
  });
};

//-------------------------  Get Loan Details  --------------------

export const GetLoanDetails =
  (navigateCallback, loanApplicationId) => (dispatch) => {
    dispatch(GetLoanDetailsLoading());
    const email = localStorage.getItem("EmailId");
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(`${base_url2}/get_my_loan_applications/${email}`, config)
      // Axios.get(`${base_url2}/get_company_loan_details/${loanApplicationId}`)
      //  Axios.get(`${base_url2}/get_company_loan_details/846`)
      .then((response) => {
        dispatch({
          type: TYPES.GET_LOAN_DETAILS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_LOAN_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
export const GetLoanDetailsById =
  (loanApplicationId, navigateCallback) => (dispatch) => {
    dispatch(GetLoanDetailsLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    // Axios.get(`${base_url2}/get_my_loan_applications/ajitksolanki@gmail.com`)
    Axios.get(
      `${base_url2}/get_company_loan_details/${loanApplicationId}`,
      config
    )
      //  Axios.get(`${base_url2}/get_company_loan_details/846`)

      .then((response) => {
        var details = [];
        details.push(response.data.data);
        dispatch({
          type: TYPES.GET_LOAN_DETAILS_SUCCESS,
          payload: details,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_LOAN_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//-------------------------  Get Loan Details Loading   --------------------

export const GetLoanDetailsLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_LOAN_DETAILS_LOADING,
  });
};

//-------------------------    Get Loan Details Clear Data  -------------------

export const GetLoanDetailsClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_LOAN_DETAILS_CLEAR_DATA,
  });
};

//------------------------- Loan Confirm  --------------------

export const LoanConfirm = (email, navigateCallback) => (dispatch) => {
  dispatch(LoanConfirmLoading());

  const loanApplicationId = localStorage.getItem("LoanApplicationId");
  const body = {
    loan_id: loanApplicationId,
    email_id: email,
    terms_condition: "true",
  };
  const saveMail = {
    id: parseInt(loanApplicationId),
    loan_id: parseInt(loanApplicationId),
    email_id: email,
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.post(
    `${base_url2}/accept_terms_send_loan_confirmation_email`,
    body,
    config
  )
    .then((response) => {
      dispatch({
        type: TYPES.LOAN_CONFIRM_SUCCESS,
        payload: response.data.type,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.LOAN_CONFIRM_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
  // Axios.post(`${base_url2}/post_email`, saveMail)
  //   .then((response) => {})
  //   .catch((error) => {});
};

//-------------------------   Loan Confirm Loading   --------------------

export const LoanConfirmLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.LOAN_CONFIRM_LOADING,
  });
};

//-------------------------     Loan Confirm Clear Data  -------------------

export const LoanConfirmClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.LOAN_CONFIRM_CLEAR_DATA,
  });
};

//------------------------- BRE  --------------------

export const BRE = (navigateCallback) => (dispatch) => {
  dispatch(BRELoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  const loanApplicationId = localStorage.getItem("LoanApplicationId");
  const body = {
    LoanApplicationId: loanApplicationId,
  };

  Axios.post(
    `${base_url2}/async_document_extract_rule_engine_execution_by_loan_application_id`,
    body,
    config
  )
    .then((response) => {
      dispatch({
        type: TYPES.BRE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.BRE_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------  BRE Loading   --------------------

export const BRELoading = () => (dispatch) => {
  dispatch({
    type: TYPES.BRE_LOADING,
  });
};

//-------------------------     BRE Clear Data  -------------------

export const BREClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.BRE_CLEAR_DATA,
  });
};

//------------------------- MyLoan  --------------------
//--- Get Loan Details Based on Loan Reference Number -- API END Point is not yet added.

export const MyLoan =
  (referenceNumber, email_id, navigateCallback) => (dispatch) => {
    dispatch(MyLoanLoading());

    const body = {
      referenceNumber: referenceNumber,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };

    Axios.get(
      `${base_url2}/get_my_loan_applications_by_reference_number/${referenceNumber}/${email_id}`,
      config
    )
      .then((response) => {
        // dispatch({
        //   type: TYPES.MY_LOAN_SUCCESS,
        //   payload: response.data,
        // });
        dispatch({
          type: TYPES.GET_LOAN_DETAILS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.MY_LOAN_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  My Loan Loading   --------------------

export const MyLoanLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.MY_LOAN_LOADING,
  });
};

//-------------------------     My Loan Clear Data  -------------------

export const MyLoanClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.MY_LOAN_CLEAR_DATA,
  });
};
//--------------------------- Get GSTIN by Pan ---------------------
export const getGstinByPan = async (pan, statecode) => {
  try {
    const response = await Axios.get(
      `${base_url2}/Get_GSTN_by_PAN/${pan}/${statecode}`
    );
    return response.data;
  } catch (error) {
    // Handle the error if necessary
    console.error(error);
    throw error;
  }
};
//---------------- Post Username and GST ---------------------//

export const GSTUsernameandGSTINPost = (gstin, username) => {
  const body = {
    gstn_num: gstin,
    user_name: username,
  };
  try {
    Axios.post(`${base_url2}/gstn_otp_generation`, body)
      .then((response) => {})
      .catch((error) => {});
  } catch (error) {
    // Handle the error if necessary
    console.error(error);
    throw error;
  }
};
//-------------------- GST OTP verification ------------------------//
export const GSTOTPVerification = async (gstin, username, otp) => {
  const body = {
    gstn_num: gstin,
    user_name: username,
    OTP: otp,
  };

  try {
    const response = await Axios.post(`${base_url2}/gstn_otp_verify`, body);
    return response;
  } catch (error) {
    // Handle the error if necessary
    console.error(error);
    throw error;
  }
};
// ------------------------ get PAN advance Data ------------------//
export const PanAdvance = async (pan, navigateCallback) => {
  const body = {
    pan: pan,
    consent: "Y",
    reason: "KYC Checking",
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(`${base_url2}/pan_advance`, body, config);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    // Handle the error if necessary
    console.error(error);
    throw error;
  }
};

//---------------- E- Sign Dialog Window--------------------

export const ESignDialogView = (status) => (dispatch) => {
  dispatch({
    type: TYPES.ESIGN_DIALOG,
    payload: status,
  });
};

//------------------------- Generate Loan Agreement  --------------------

export const GenerateLoanAgreement =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(GenerateLoanAgreementLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    let data = null;
    data = new FormData();
    data.append("loan_id", loan_id);

    Axios.get(`${base_url2}/get_loan_agreement/${loan_id}`, config)
      .then((response) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_AGREEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_AGREEMENT_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  Generate Loan Agreement Loading   --------------------

export const GenerateLoanAgreementLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GENERATE_LOAN_AGREEMENT_LOADING,
  });
};

//-------------------------     Generate Loan Agreement Clear Data  -------------------

export const GenerateLoanAgreementClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GENERATE_LOAN_AGREEMENT_CLEAR_DATA,
  });
};

//------------------------- ESign  --------------------

export const ESign =
  (loan_id, base64, type, navigateCallback) => (dispatch) => {
    dispatch(ESignLoading());

    const body = {
      loan_id: String(loan_id),
      data: String(base64),
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(`${base_url2}/${type}`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.ESIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ESIGN_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//----------------------- E sign Loan Agreement ---------------------
export const ESignAgreement =
  (loan_id, base64, navigateCallback) => (dispatch) => {
    dispatch(ESignAgreementLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = {
      loan_id: String(loan_id),
      data: String(base64),
    };

    Axios.post(`${base_url2}/esign_loan_agreement`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.ESIGNAGREEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ESIGNAGREEMENT_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//-------------------------   ESign Loading   --------------------

export const ESignLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGN_LOADING,
  });
};
//------------------------ E sign Agreement Loading ---------
export const ESignAgreementLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNAGREEMENT_LOADING,
  });
};
//-------------------------      ESign Clear Data  -------------------

export const ESignClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGN_CLEAR_DATA,
  });
};

//------------------------- EStamp  --------------------

export const EStamp = (loan_id, file_path) => (dispatch) => {
  dispatch(EStampLoading());

  let data = null;
  data = new FormData();
  data.append("loan_id", loan_id);
  data.append("file_name", file_path);

  Axios.post(`${base_url2}/eStamp`, data)
    .then((response) => {
      dispatch({
        type: TYPES.ESTAMP_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ESTAMP_FAIL,
      });
    });
};

//-------------------------   EStamp Loading   --------------------

export const EStampLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESTAMP_LOADING,
  });
};

//-------------------------      EStamp Clear Data  -------------------

export const EStampClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ESTAMP_CLEAR_DATA,
  });
};

//------------------------- ESign Check  --------------------

export const ESignCheck = (loan_id, navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  dispatch(ESignCheckLoading());
  Axios.get(`${base_url2}/getSignedDocumentApplicationForm/${loan_id}`, config)
    .then((response) => {
      dispatch({
        type: TYPES.ESIGN_CHECK_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ESIGN_CHECK_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------   ESign Check Loading   --------------------

export const ESignCheckLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGN_CHECK_LOADING,
  });
};

//-------------------------      ESign Check Clear Data  -------------------

export const ESignCheckClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGN_CHECK_CLEAR_DATA,
  });
};
//------------------ Reset Password --------------------------//
export const resetPasswordChange = async (data) => {
  try {
    const response = await Axios.post(`${base_url2}/reset_password`, {
      email_id: data.username,
      current_password: data.oldPassword,
      new_password: data.password,
      tablename: "dla",
    });
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//------------------------- Upload Identification Card   --------------------

export const uploadIdentificationCard = (loan_id, file) => async (dispatch) => {
  let data = null;
  data = new FormData();
  data.append("source", "dla");
  data.append("loan_id", loan_id);
  data.append("file", file);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/dla/customer_file_upload`,
      data,
      config
    );
  } catch (error) {}
};

//------------------------- Enach Register  --------------------

export const Enach = (loan_id, auth, navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  dispatch(EnachLoading());
  const body = {
    loan_id: String(loan_id),
    auth: auth,
  };

  Axios.post(`${base_url2}/enach_register`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.ENACH_REGISTER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ENACH_REGISTER_FAIL,
        payload: error.response.data,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------   Enach Register Loading   --------------------

export const EnachLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ENACH_REGISTER_LOADING,
  });
};

//-------------------------      Enach Register Clear Data  -------------------

export const EnachClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ENACH_REGISTER_CLEAR_DATA,
  });
};

//-------------------------  Enach Status  --------------------

export const EnachStatus = (loan_id, navigateCallback) => (dispatch) => {
  dispatch(EnachStatusLoading());
  const body = {
    loan_id: String(loan_id),
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  Axios.post(`${base_url2}/getMandateDetails`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.ENACH_STATUS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.ENACH_STATUS_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------    Enach Status Loading   --------------------

export const EnachStatusLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ENACH_STATUS_LOADING,
  });
};

//-------------------------       Enach Status Clear Data  -------------------

export const EnachStatusClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ENACH_STATUS_CLEAR_DATA,
  });
};

//-------------------------  initiate Debit  --------------------

export const InitiateDebitFunction =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(InitiateDebitLoading());
    const body = {
      loan_id: String(loan_id),
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };

    Axios.post(`${base_url2}/initiateDebit`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.INITIATE_DEBIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.INITIATE_DEBIT_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  initiate Debit Loading   --------------------

export const InitiateDebitLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.INITIATE_DEBIT_LOADING,
  });
};

//-------------------------   initiate Debit Clear Data  -------------------

export const InitiateDebitLoadingClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.INITIATE_DEBIT_CLEAR_DATA,
  });
};

//-------------------------Generate Loan Application --------------------

export const GenerateLoanApplication =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(GenerateLoanApplicationLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(`${base_url2}/getApplicationForm/${loan_id}`, config)
      .then((response) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_APPLICATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_APPLICATION_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  Generate Loan Application Loading   --------------------

export const GenerateLoanApplicationLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GENERATE_LOAN_APPLICATION_LOADING,
  });
};

//-------------------------    Generate Loan Application Clear Data  -------------------

export const GenerateLoanApplicationClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GENERATE_LOAN_APPLICATION_CLEAR_DATA,
  });
};

//------------------------- Get Sanction Letter --------------------

export const GetSanctionLetter = (loan_id, navigateCallback) => (dispatch) => {
  dispatch(GetSanctionLetterLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url2}/get_sanction_letter/${loan_id}`, config)
    .then((response) => {
      dispatch({
        type: TYPES.GET_SANCTION_LETTER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.GET_SANCTION_LETTER_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------  Get Sanction Letter Loading   --------------------

export const GetSanctionLetterLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_SANCTION_LETTER_LOADING,
  });
};

//-------------------------    Get Sanction Letter Clear Data  -------------------

export const GetSanctionLetterClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_SANCTION_LETTER_CLEAR_DATA,
  });
};

//------------------------- Save Sanction Letter --------------------

export const SaveSanctionLetter =
  (loan_id, data, navigateCallback) => (dispatch) => {
    dispatch(SaveSanctionLetterLoading());
    const body = {
      loan_id: loan_id,
      data: data,
      flag: "True",
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(`${base_url2}/save_sanction_letter`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.SAVE_SANCTION_LETTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.SAVE_SANCTION_LETTER_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  Save Sanction Letter Loading   --------------------

export const SaveSanctionLetterLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_SANCTION_LETTER_LOADING,
  });
};

//-------------------------    Save Sanction Letter Clear Data  -------------------

export const SaveSanctionLetterClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.SAVE_SANCTION_LETTER_CLEAR_DATA,
  });
};
//----- Clear the Loan Agreement--------------------
export const SaveLoanAgreementClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNINITIATEAGREEMENT_CLEAR_DATA,
  });
};
//----- Clear the Loan Agreement--------------------
export const SaveLoanAgreementInititateClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNAGREEMENT_CLEAR_DATA,
  });
};
export const AgreementDataClear = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNINITIATEAGREEMENT_CLEAR_DATA,
  });
};
//------------------------- Get Sanction Letter Status --------------------

export const GetSanctionLetterStatus =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(GetSanctionLetterStatusLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(`${base_url2}/get_sanction_letter_flag/${loan_id}`, config)
      .then((response) => {
        dispatch({
          type: TYPES.GET_SANCTION_STATUS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GET_SANCTION_STATUS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

//-------------------------  Get Sanction Letter Status Loading   --------------------

export const GetSanctionLetterStatusLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_SANCTION_STATUS_LOADING,
  });
};

//-------------------------    Get Sanction Letter Status Clear Data  -------------------

export const GetSanctionLetterStatusClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.GET_SANCTION_STATUS_CLEAR_DATA,
  });
};

//------------------------- Enach  Status --for all the loan idss --------------------

export const Status = (loan_id, navigateCallback) => (dispatch) => {
  dispatch(StatusLoading());
  const body = {
    loan_id: String(loan_id),
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.post(`${base_url2}/getMandateDetails`, body, config)
    .then((response) => {
      dispatch({
        type: TYPES.STATUS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.STATUS_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------  Enach  Status --for all the loan ids Loading   --------------------

export const StatusLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.STATUS_LOADING,
  });
};

//-------------------------    Enach  Status --for all the loan ids Clear Data  -------------------

export const StatusClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.STATUS_CLEAR_DATA,
  });
};
//---------------------------- Upload Passport ----------------//
//------------------------- Upload Identification Card   --------------------

export const uploadPassport =
  (loan_id, file, navigateCallback) => async (dispatch) => {
    let data = null;
    data = new FormData();
    data.append("source", "dla");
    data.append("loan_id", loan_id);
    data.append("file", file);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };

    Axios.post(`${base_url2}/cms_passport_upload`, data, config)
      .then((response) => {})
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//---------------- E- Sign Dialog Window--------------------
export const uploadPAN =
  (loan_id, file, navigateCallback) => async (dispatch) => {
    let data = null;
    data = new FormData();
    data.append("source", "dla");
    data.append("loan_id", loan_id);
    data.append("file", file);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };

    Axios.post(`${base_url2}/cms_pan_upload`, data, config)
      .then((response) => {})
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
export const UploadDocumentDialog = (status) => (dispatch) => {
  dispatch({
    type: TYPES.UPLOAD_DIALOG,
    payload: status,
  });
};
//------------ Get Tenure ------------------------//
export const getTenure = (loanProductId, navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  Axios.get(
    `${base_url2}/masters/getAllLoanTenureMaster/${loanProductId}`,
    config
  )
    .then((response) => {
      dispatch({
        type: TYPES.TENURE,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//-------------------------  Save Sanction Letter Loading   --------------------

export const saveLoanAgreementLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNAGREEMENT_LOADING,
  });
};
export const saveLoanInitiateLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.ESIGNINITIATEAGREEMENT_LOADING,
  });
};
//------------------ Save Loan Agreement-----------------------
export const SaveLoanAgreement =
  (loan_id, data, navigateCallback) => (dispatch) => {
    dispatch(saveLoanInitiateLoading());
    const body = {
      loan_id: loan_id,
      data: data,
      flag: "True",
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(`${base_url2}/save_loan_agreement`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.ESIGNINITIATEAGREEMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ESIGNINITIATEAGREEMENT_LOADING,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//------------------------ Change Missing Document   --------------------

export const misssingDocumentStatus = (loan_id, status) => async (dispatch) => {
  try {
    const response = await Axios.post(
      `${base_url2}/saveMissingDocumentStatus`,
      {
        loan_id: loan_id,
        missing_documents: status,
      }
    );
  } catch (error) {}
};
//-------------------------E signed Loan Application Download Button  --------------------

export const GetEsignedLoanApplication =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/getSignedDocumentApplicationForm/${loan_id}`,
        config
      );
      dispatch({
        type: TYPES.LOAN_APPLICATION_DOWNLOAD_DATA,
        payload: response.data,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//-------------------------E Sanction Letter  --------------------

export const GetEsanctionLetter =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/getSignedDocumentSanctionLetter/${loan_id}`,
        config
      );
      dispatch({
        type: TYPES.SANCTION_LETTER_DOWNLOAD_DATA,
        payload: response.data,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//-------------------------E Loan Agreement --------------------

export const GetELoanAgreement =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/getSignedDocumentLoanAgreement/${loan_id}`,
        config
      );
      dispatch({
        type: TYPES.LOAN_AGREEMENT_DOWNLOAD_DATA,
        payload: response.data,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//------------ Get Martial Status ------------------------//
export const getMaritaldropdown = (navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url2}/masters/getAllMaritalStatus`, config)
    .then((response) => {
      dispatch({
        type: TYPES.MARITALSTATUS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};
//------------ Get Branches for registration ------------------------//
export const getBranches = () => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url2}/dla/masters/getAllBranch`, config).then(
    (response) => {
      dispatch({
        type: TYPES.BRANCHNAME,
        payload: response.data,
      });
    }
  );
};
//------------ Get Bank Names ------------------------//
export const getBankNames = (navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  Axios.get(`${base_url2}/dla/masters/getAllFinbitBankNames`, config)
    .then((response) => {
      dispatch({
        type: TYPES.BANKNAMES,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};
//-------------------------E Loan Agreement Start Date --------------------

export const GetELoanAgreementStartDate =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/get_emi_start_date_choice/${loan_id}`,
        config
      );
      dispatch({
        type: TYPES.LOAN_AGREEMENT_START_DATE,
        payload: response.data,
      });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//------------------------ post Start Date    --------------------

export const saveLoanAgreementStartDate =
  (loan_id, startDate, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.post(
        `${base_url2}/save_emi_start_date_choice/${loan_id}`,
        { start_date: startDate },
        config
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//-------------------------Repayment Create Modify Schedule --------------------

export const modifyRepaymentSchedule =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/create_modify_repayment_schedule/${loan_id}`,
        config
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };
//-------------------------Repayment Send Modify Schedule --------------------

export const sendRepaymentSchedule =
  (loan_id, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    try {
      const response = await Axios.get(
        `${base_url2}/send_repayment_schedule_email/${loan_id}`,
        config
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    }
  };

//------------------------- GenInsight User Query   --------------------
// Get the JWT token from local storage
const jwtToken = localStorage.getItem("jwt_token");

// Create headers object conditionally
const headers = jwtToken
  ? { "Access-Control-Allow-Origin": "*", Authorization: `Bearer ${jwtToken}` }
  : { "Access-Control-Allow-Origin": "*" };

export const UserQuery = (user_query, typeOfLoan) => async (dispatch) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
  };

  const jwtToken = localStorage.getItem("jwt_token");
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  dispatch(UserQueryLoading());
  const body = {
    user_query: user_query,
    loan_type: typeOfLoan,
  };
  await Axios.post(
    `${genInsight_base_url}api/v1/dlaAgent/support/reply`,
    body,
    {
      withCredentials: false,
      headers: headers,
    }
  )
    .then((response) => {
      dispatch({
        type: TYPES.USER_QUERY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        dispatch({
          type: TYPES.USER_QUERY_NETWORK_ERROR,
        });
        return;
      }
      if (error.response.status === 401) {
        dispatch({
          type: TYPES.USER_QUERY_FAIL,
        });
        return;
        // if (callback) {
        //   callback();
        //   dispatch({
        //     type: TYPES.USER_QUERY_FAIL,
        //   });
        // }
      }
    });
};

//-------------------------  UserQuery Loading  --------------------

export const UserQueryLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.USER_QUERY_LOADING,
  });
};

//-------------------------  UserQuery  Clear Data-------------------

export const UserQueryClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.USER_QUERY_CLEAR_DATA,
  });
};

export const ChatMessages = () => async (dispatch) => {
  dispatch(ChatMessagesLoading());
  const headers = {
    "Access-Control-Allow-Origin": "*",
  };

  const jwtToken = localStorage.getItem("jwt_token");
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  await Axios.get(`${genInsight_base_url}api/v1/dlaAgent/support/messages`, {
    withCredentials: false,
    headers: headers,
  })
    .then((response) => {
      let chat_history = [];
      response.data.response_data.map((res_data, index) => {
        if (res_data.bot_response !== undefined) {
          // Add user message
          chat_history.push({
            content: res_data.user_query,
            isUser: true,
            typing: false,
            messageID: res_data.id,
            time: res_data.inserted_datetime,
          });

          // Add bot message
          chat_history.push({
            content: res_data.bot_response,
            isUser: false,
            typing: false,
            messageID: res_data.id,
            time: res_data.inserted_datetime,
          });
        }
      });
      dispatch({
        type: TYPES.CHAT_MESSAGES_SUCCESS,
        payload: chat_history,
      });
    })
    .catch((error) => {
      if (error.code === "ERR_NETWORK") {
        dispatch({
          type: TYPES.CHAT_MESSAGES_NETWORK_ERROR,
        });
        return;
      }
      if (error.response.status === 401) {
        dispatch({
          type: TYPES.CHAT_MESSAGES_FAIL,
        });
        return;
        // if (callback) {
        //   callback();
        //   dispatch({
        //     type: TYPES.CHAT_MESSAGES_FAIL,
        //   });
        // }
      }
    });
};

//-------------------------  ChatMessages Loading  --------------------

export const ChatMessagesLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.CHAT_MESSAGES_LOADING,
  });
};

//-------------------------  ChatMessages  Clear Data-------------------

export const ChatMessagesClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.CHAT_MESSAGES_CLEAR_DATA,
  });
};
export const RegistrationUser = async (registrationDetails, channelName) => {
  const body = {
    // email_id: registrationDetails.email,
    // user_name: registrationDetails.username,
    // mobile_no: registrationDetails.mobileNo,
    // email_id: registrationDetails.email,
    mobile_no: registrationDetails.mobileNo,
    user_name: registrationDetails.username,
    // zipcode: registrationDetails.zipcode,
    user_captcha: registrationDetails.user_captcha,
    session_id: registrationDetails.session_id,
    channel_name: channelName,
  };
  try {
    const response = await Axios.post(`${base_url2}/digitalLoans/signup`, body);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    return error.response;
  }
};
export const GetLoanProductType = async (employmenttype, navigateCallback) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const response = await Axios.get(
      `${base_url2}/masters/getAllLoanProducts/${employmenttype}`,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
  }
};
//------------------- Get loan journey status-----------//
export const isLoanJourneyPending = async () => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const response = await Axios.get(`${base_url2}/is_pending_loan`, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------Send Verification code-----------//
export const sendverificationCode = async (mobileNo) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    const body = {
      mobile_no: mobileNo,
    };
    const response = await Axios.post(
      `${base_url2}/crh/sendMobileOtp`,
      body,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// verify otp mobile
export const VerifyOTPMobile = (mobileNo, otp) => async (dispatch) => {
  const body = {
    otp: otp,
    mobile_no: mobileNo,
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/verify-otp-mobile`,
      body,
      config
    );
    dispatch({
      type: TYPES.VERIFY_OTP_MOBILE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.VERIFY_OTP_MOBILE_FAIL,
    });
  }
};

// ------------------- Verify PAN ------------------ //
export const verifyPan = (pan, navigateCallback) => async (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  const body = { pan: pan, consent: "Y", reason: "KYC check" };
  try {
    const response = await Axios.post(
      `${base_url2}/pan-verification`,
      body,
      config
    );
    dispatch({
      type: TYPES.VERIFY_PAN_SUCCESS,
      payload: response.data?.response_data,
    });
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) {
        navigateCallback();
      }
      return error.response;
    }
    dispatch({ type: TYPES.VERIFY_PAN_FAIL });
  }
};

export const navigateFromDigitalLoans = () => (dispatch) => {
  dispatch({
    type: TYPES.NAVIGATE_FROM_DIGITAL_LOANS,
  });
};
export const navigateFromLogin = () => (dispatch) => {
  dispatch({
    type: TYPES.NAVIGATE_FROM_LOGIN,
  });
};
//-------------------------Generate Loan Application --------------------

export const GenerateLoanApplicationDigitalLoans =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(GenerateLoanApplicationLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.get(
      `${base_url2}/loanAccept/digitalLoans/getApplicationForm/${loan_id}`,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_APPLICATION_SUCCESS,
          payload: response.data?.response_data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_APPLICATION_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//------------------------- ESign  --------------------

export const ESignDigitalLoans =
  (loan_id, base64, navigateCallback) => (dispatch) => {
    dispatch(ESignLoading());

    const body = {
      loan_id: String(loan_id),
      data: String(base64),
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    Axios.post(
      `${base_url2}/loanAccept/digitalLoans/eSignApplicationForm`,
      body,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.ESIGN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ESIGN_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//------------------------- Generate Loan Agreement  --------------------

export const GenerateLoanAgreementDigitalLoan =
  (loan_id, navigateCallback) => (dispatch) => {
    dispatch(GenerateLoanAgreementLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    let data = null;
    data = new FormData();
    data.append("loan_id", loan_id);

    Axios.get(
      `${base_url2}/loanAccept/digitalLoans/getLoanAgreement/${loan_id}`,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_AGREEMENT_SUCCESS,
          payload: response.data?.response_data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.GENERATE_LOAN_AGREEMENT_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//----------------------- E sign Loan Agreement ---------------------
export const ESignAgreementDigitalLoans =
  (loan_id, base64, navigateCallback) => (dispatch) => {
    dispatch(ESignAgreementLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = {
      loan_id: String(loan_id),
      data: String(base64),
    };

    Axios.post(
      `${base_url2}/loanAccept/digitalLoans/eSignLoanAgreement`,
      body,
      config
    )
      .then((response) => {
        dispatch({
          type: TYPES.ESIGNAGREEMENT_SUCCESS,
          payload: response.data.response_data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ESIGNAGREEMENT_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
//------------------- Send Verification code-----------//
export const sendverificationCodeEmail = async () => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const response = await Axios.get(`${base_url2}/presaf/verifyEmail`, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// verify otp mobile
export const VerifyOTPEmail = (otp) => async (dispatch) => {
  const body = {
    user_otp: otp,
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/presaf/verifyEmailOtp`,
      body,
      config
    );
    dispatch({
      type: TYPES.VERIFY_OTP_EMAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.VERIFY_OTP_EMAIL_FAIL,
    });
  }
};
export const aadharDrawerCoborrower1 = (status) => (dispatch) => {
  dispatch({
    type: TYPES.AADHAR_DRAWER_COBORROWER,
    payload: status,
  });
};
export const VerifyOTPCoborrower =
  (otp, session_id, isCoBorrower) => async (dispatch) => {
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    dispatch(VerifyOTPLoadingCoborrower());
    const body = {
      loan_id: loanApplicationId,
      otp: otp,
      session_id: session_id,
      is_coborrower: isCoBorrower,
    };

    try {
      const response = await Axios.post(
        `${base_url2}/verify_otp_get_response`,
        body
      );
      dispatch({
        type: TYPES.VERIFY_OTP_COBORROWER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.VERIFY_OTP_COBORROWER_FAIL,
      });
    }
  };
export const VerifyOTPLoadingCoborrower = () => (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_OTP_COBORROWER_LOADING,
  });
};
export const verifyPanCoborrowwer =
  (pan, navigateCallback) => async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = { pan: pan, consent: "Y", reason: "KYC check" };
    try {
      const response = await Axios.post(
        `${base_url2}/pan-verification`,
        body,
        config
      );
      console.log(response);
      dispatch({
        type: TYPES.VERIFY_PAN_SUCCESS_COBORROWER,
        payload: response.data?.response_data,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
      dispatch({ type: TYPES.VERIFY_PAN_FAIL_COBORROWER });
    }
  };
//------------------- Send Verification code-----------//
export const sendverificationCodeEmailCoborrower = async (email) => {
  try {
    const body = {
      email_id: email,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const response = await Axios.post(
      `${base_url2}/coapp/verifyEmail`,
      body,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// verify otp mobile
export const VerifyOTPEmailCoborrower = (otp, email) => async (dispatch) => {
  const body = {
    email_id: email,
    user_otp: otp,
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/coapp/verifyEmailOtp`,
      body,
      config
    );
    dispatch({
      type: TYPES.VERIFY_OTP_EMAIL_COBORROWER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.VERIFY_OTP_EMAIL_COBORROWER_FAIL,
    });
  }
};
//------------------------- Enach Register  --------------------

export const Enach1A =
  (loan_id, auth, accountDetails, navigateCallback) => (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    dispatch(EnachLoading());
    const body = {
      loan_id: String(loan_id),
      auth: auth,
      account_details: accountDetails,
    };

    Axios.post(`${base_url2}/enach_register`, body, config)
      .then((response) => {
        dispatch({
          type: TYPES.ENACH_REGISTER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: TYPES.ENACH_REGISTER_FAIL,
          payload: error.response.data,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };

// ------------------------ Video Kyc : Start ----------------------------
export const videoKycStart = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  try {
    const response = await Axios.get(
      `${base_url2}/postSaf/startVideoKyc`,
      config
    );
    return response.data?.response_data;
  } catch (error) {
    return null;
  }
};

//------------------------- Video Kyc : Validate frame  --------------------
export const videoKycValidateFrame = (ref_id, frame) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  const body = { ref_id: ref_id, frame: frame };

  Axios.post(`${base_url2}/postSaf/videoKyc/validateFrame`, body, config)
    .then()
    .catch((error) => {
      if (error.response && error.response.status == 400) {
        throw error;
      }
    });
};

// ---------------------- Video Kyc : Liveliness Check ---------------------------

export const videoKycLivelinessCheck = async (ref_id) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  try {
    const response = await Axios.get(
      `${base_url2}/postSaf/videoKyc/livelinessCheck?ref_id=${ref_id}`,
      config
    );
    return response.data?.response_data;
  } catch (error) {
    return false;
  }
};
//-------------------------  Get Quick apply details --------------------

export const getCustomerDetails = (token) => (dispatch) => {
  dispatch(QuickapplydetailsLoading());
  Axios.get(`${base_url2}/crh/getCustomerDetails?applyToken=${token}`)
    .then((response) => {
      dispatch({
        type: TYPES.QUICK_APPLY_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: TYPES.QUICK_APPLY_DETAILS_FAIL,
      });
    });
};

//-------------------------- Get Quick apply details by phone number ------------------

export const getCustomerDetailsByPhone =
  (phoneNo, jwt_token) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${jwt_token}`,
        },
      };
      const response = await Axios.get(
        `${base_url2}/crh/getCustomerDetails/${phoneNo}`,
        config
      );
      if (response.status === 200) {
        dispatch({
          type: TYPES.QUICK_APPLY_DETAILS_SUCCESS,
          payload: response.data,
        });
        return true;
      } else {
        dispatch({
          type: TYPES.QUICK_APPLY_DETAILS_FAIL,
        });
        return false;
      }
    } catch (error) {
      dispatch({
        type: TYPES.QUICK_APPLY_DETAILS_FAIL,
      });
      return false;
    }
  };

//------------------- Send Verification code-----------//
export const verifyEmailMobile = async (
  isEmail,
  email,
  mobile_no,
  user_otp,
  fullname
) => {
  try {
    const body = {
      is_email: isEmail,
      email_id: email,
      mobile_no: mobile_no,
      user_otp: user_otp,
      fullname: fullname,
    };
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const response = await Axios.post(
      `${base_url2}/crh/verifyEmailMobile`,
      body,
      config
    );
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const SAF1 =
  (
    employmentType,
    pan,
    aadhaar,
    loanProduct,
    loanAmount,
    loanTenure,
    emailAddress,
    mobileNumber,
    dateofbirth,
    panConformation,
    aadhaarConformation,
    beauroScoreConformation,
    valueOfCollateral,
    CIN,
    aadharDetails,
    maritalStatusSelected,
    hasOwnPropertySelected,
    panDetails,
    data,
    callback
  ) =>
  (dispatch) => {
    let branch_name = localStorage.getItem("branch");
    let propertySelected = "";
    if (branch_name == "null") {
      var branch = "direct";
    } else {
      var branch = branch_name;
    }
    if (panConformation === true) {
      var panString = "Y";
    } else {
      var panString = "N";
    }

    if (aadhaarConformation === true) {
      var aadhaarString = "Y";
    } else {
      var aadhaarString = "N";
    }

    if (beauroScoreConformation === true) {
      var beauroScoreString = "Y";
    } else {
      var beauroScoreString = "N";
    }
    if (CIN == undefined) {
      CIN = "";
    }
    if (hasOwnPropertySelected == "true") {
      propertySelected = true;
    }
    if (hasOwnPropertySelected == "false") {
      propertySelected = false;
    }

    dispatch(SAFLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    localStorage.setItem("employmentType", employmentType);
    localStorage.setItem("loanProduct", loanProduct);
    dispatch({
      type: TYPES.IDENTIFY_EMPLOYMENT_TYPE,
      payload: employmentType,
    });
    dispatch({
      type: TYPES.IDENTIFY_LOANPRODUCT_TYPE,
      payload: loanProduct,
    });
    const email = emailAddress;
    if (employmentType == 1 || employmentType == 2 || employmentType == 7) {
      var aadharData;
      if (aadharDetails.data == undefined) {
        aadharData = "";
      } else {
        aadharData = aadharDetails.data;
      }
      const body = {
        EmploymentTypeId: employmentType,
        LoanProductId: loanProduct,
        PAN: pan,
        Aadhar: aadhaar,
        aadhar_verify: "YES",
        LoanAmount: parseInt(loanAmount),
        LoanTenure: parseInt(loanTenure),
        Aadhar_card_status: "VALID",
        EmailId: email,
        email_id: email,
        Phone: mobileNumber,
        DateofBirth: dateofbirth,
        pan_consent: "Y",
        pan_details: panDetails,
        aadhar_details: aadharData,
        branch: "Head Office",
        marital_status: maritalStatusSelected,
        own_house: propertySelected,
        coApplicantData: data,
        Consent: [
          {
            ConsentType: "PAN",
            ConsentValue: "Y",
          },
          {
            ConsentType: "Aadhaar",
            ConsentValue: "Y",
          },
          {
            ConsentType: "CreditBureau",
            ConsentValue: "Y",
          },
        ],
      };
      Axios.post(`${base_url2}/v3/submitSaf`, body, config)
        .then((response) => {
          localStorage.setItem(
            "LoanApplicationId",
            response.data.data.LoanApplicationId
          );
          dispatch({
            type: TYPES.SAF_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            if (callback) {
              callback();
              dispatch({
                type: TYPES.SAF_FAIL,
              });
            }
          } else {
            dispatch({
              type: TYPES.SAF_FAIL,
            });
          }
        });
    } else {
      const body = {
        EmploymentTypeId: employmentType,
        LoanProductId: loanProduct,
        PAN: pan,
        pan_details: panDetails,
        Aadhar: aadhaar,
        LoanAmount: parseInt(loanAmount),
        LoanTenure: parseInt(loanTenure),
        emailid: email,
        EmailId: email,
        email_id: email,
        Phone: mobileNumber,
        DateofBirth: dateofbirth,
        valueOfCollateral: valueOfCollateral,
        CIN: CIN,
        aadhar_verify: "YES",
        pan_consent: "Y",
        aadhar_details: "",
        branch: branch,
        marital_status: maritalStatusSelected,
        own_house: propertySelected,
      };
      Axios.post(`${base_url2}/v3/submitSaf`, body, config)
        .then((response) => {
          localStorage.setItem(
            "LoanApplicationId",
            response.data.data.LoanApplicationId
          );
          dispatch({
            type: TYPES.SAF_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            if (callback) {
              callback();
              dispatch({
                type: TYPES.SAF_FAIL,
              });
            }
          } else {
            dispatch({
              type: TYPES.SAF_FAIL,
            });
          }
        });
    }
  };
export const SaveaadharInformation = (adharData) => async (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_OTP_SUCCESS,
    payload: adharData,
  });
};

export const SavePANInformation = (panData) => async (dispatch) => {
  dispatch({
    type: TYPES.VERIFY_PAN_SUCCESS,
    payload: panData,
  });
};

export const saveBulkUpload = async (file, callback) => {
  let data = null;
  data = new FormData();
  data.append("file", file);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/v3/bulkapply`,
      data,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      callback();
    }
  }
};
export const statementUploadBulk = async (newData, callback) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };

  try {
    const response = await Axios.post(
      `${base_url2}/sharepoint/uploadDocument`,
      { loans: newData },
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      callback();
    }
  }
};

//------------------- Send Verification code-----------//
export const sendverificationCodeDigital = async (mobileNo) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    const body = {
      mobile_no: mobileNo,
    };
    const response = await Axios.post(
      `${base_url2}/digitalLoans/sendMobileOtp`,
      body,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// verify otp mobile
export const VerifyOTPMobileDigital = (mobileNo, otp) => async (dispatch) => {
  const body = {
    mobile_no: mobileNo,
    user_otp:otp
  };
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url2}/digitalLoans/verifyMobileOtp`,
      body,
      config
    );
    dispatch({
      type: TYPES.VERIFY_OTP_MOBILE_DIGITAL_LOANS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.VERIFY_OTP_MOBILE_DIGITAL_LOANS_FAIL,
    });
  }
};

//-----------------------------------LOGIN SIGN UP PAGE----------------------------------

//---------------------Forgot password generate otp
export const forgotPasswordOtpGenerate = (email_o_phone) => async (dispatch)=>{
  const body={
    email_o_phone:email_o_phone,
  };
  try{
    const response = await Axios.post(
      `${base_url2}/forgot-password`, body
    );
    if(response){
      return response.data;
    }
    else{
      return [];
    }
  }catch(error){
    throw new Error(error);
  }
};

//---------------------Forgot password validate otp with new password
export const forgotPasswordOtpValidate = (otp, password) => async (dispatch) => {
  const body={
    otp:otp,
    password:password,
  };
  try{
    const response = await Axios.post(
      `${base_url2}/update-password`, body
    );
    if(response){
      return response.data;
    }
    else{
      return [];
    }
  }catch(error){
    throw new Error(error);
  }
};

//----------------------------------- KFS DOC API ---------------------------------------
export const KFSDocument = (loan_id) => async(dispatch) => {
  const body={
    loan_id:loan_id
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try{
    const response = await Axios.post(`${base_url2}/get_kfs_doc`, body, config);
    if(response){
      return response;
    }
    else{
      return response;
    }
  }catch(error){
    throw new Error(error);
  }
}
  
// export const KFSDocument = (loan_id) => async(dispatch) => {
//   // dispatch(KfsDocumentLoading());
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
//     },
//   };
//   const body={
//     loan_id:loan_id
//   }
//   Axios.post(`${base_url2}/get_kfs_doc`, body, config)
//       .then((response) => {
//         dispatch({
//           type: TYPES.KFS_DOCUMENT_SUCCESS,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: TYPES.KFS_DOCUMENT_FAIL,
//         });
//         if (error.response && error.response.status === 401) {
//           // navigateCallback();
//         }
//     });
// }

export const KfsDocumentLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.KFS_DOCUMENT_LOADING,
  });
};
//----------------------------------- KFS DOC API STATUS---------------------------------------
export const KFSDocumentFlag = (loan_id) => async(dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
    },
  };
  try{
    const response = await Axios.get(`${base_url2}/doc_flags/${loan_id}`, config);
    if(response){
      return response.data.data;
    }
    else{
      return response;
    }
  }catch(error){
    throw new Error(error);
  }
}


