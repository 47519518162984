export const initialData = {
  data: {
    code: 0,
    data: {
      address: {
        careOf: "",
        country: "",
        district: "",
        house: "",
        landmark: "",
        locality: "",
        pin: "",
        postOffice: "",
        state: "",
        street: "",
        subDistrict: "",
        vtc: "",
      },
      dateOfBirth: "",
      email: "",
      gender: "",
      generatedAt: "",
      maskedNumber: "",
      name: "",
      phone: "",
      photo: "",
    },
    message: "",
    timestamp: "",
    transaction_id: "",
  },
  save_status: "success",
  type: "success",
};

export const initialPANData = {
  code: 200,
  data: {
    aadhaar_linked: true,
    address: {
      city: "",
      country: "",
      full: "",
      line_1: "",
      line_2: "",
      state: "",
      street_name: "",
      zip: "",
    },
    category: "",
    dob: "",
    email: null,
    first_name: "",
    full_name: "",
    full_name_split: ["", "", ""],
    gender: "",
    last_name: "",
    masked_aadhaar: "",
    middle_name: "",
    pan_number: "",
    phone_number: null,
    status: "VALID",
  },
  message: "Pan Verified Successfully.",
  sub_code: "SUCCESS",
  timestamp: null,
  transaction_id: "",
  response_message: "SUCCESS",
  //TEST
  // "response_data": {
  //     "code": 200,
  //     "data": {
  //         "aadhaar_linked": true,
  //         "address": {
  //             "city": "",
  //             "country": "",
  //             "full": "",
  //             "line_1": "",
  //             "line_2": "",
  //             "state": "",
  //             "street_name": "",
  //             "zip": ""
  //         },
  //         "category": "person",
  //         "dob": "2002-06-14",
  //         "email": null,
  //         "first_name": "TANYA",
  //         "full_name": "TANYA SONY",
  //         "full_name_split": [
  //             "TANYA",
  //             "",
  //             "SONY"
  //         ],
  //         "gender": "F",
  //         "last_name": "SONY",
  //         "masked_aadhaar": "XXXXXXXX2784",
  //         "middle_name": "",
  //         "pan_number": "PYTPS4537Q",
  //         "phone_number": null,
  //         "status": "VALID"
  //     },
  //     "message": "Pan Verified Successfully.",
  //     "sub_code": "SUCCESS",
  //     "timestamp": 1731442441948,
  //     "transaction_id": "9cf05e268f4348d8af6805e904ed75c8"
  // },
  // "response_message": "SUCCESS"
};

export const gender = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];
export const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];
